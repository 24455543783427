import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import AppHeader from '../common/AppHeader';

const { Content } = Layout;

const AppLayout = ({ middle }: { middle?: boolean }) => {
  return (
    <Layout className="app-layout">
      <AppHeader />
      <Content className={`${middle ? 'middle' : ''}`}>
        <div className={`${middle ? 'middle-container' : ''}`}>
          <Outlet />
        </div>
      </Content>
    </Layout>
  );
};
export default AppLayout;
