import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { ROUTES } from '../../common/constants';
import { handleGraphQlSuccess } from '../../common/utils';
import ScreenLayout from '../../components/layouts/ScreenLayout';
import useQueryParams from '../../hooks/useQueryParams';
import useRouter from '../../hooks/useRouter';
import FormLoading from '../form/editor/utils/FormLoading';
import { ACCEPT_WORKSPACE_INVITE } from './graphql/mutations';

export default function VerifyWorkspaceInvite() {
  const { getQueryParam } = useQueryParams();
  const { navigate } = useRouter();
  const invitationId = getQueryParam('id') as string;
  const [acceptWorkspaceInvite, { loading }] = useMutation(
    ACCEPT_WORKSPACE_INVITE,
    {
      onError: () => {},
    },
  );

  useEffect(() => {
    if (invitationId) {
      acceptWorkspaceInvite({
        variables: {
          data: { invitationId },
        },
        onCompleted: (res) => {
          handleGraphQlSuccess(res.acceptInvitation?.message);
          navigate(ROUTES.MAIN);
        },
        onError: () => {
          navigate(ROUTES.LOGIN, { replace: true });
        },
      });
    } else {
      navigate(ROUTES.LOGIN, { replace: true });
    }
  }, []);

  if (loading) {
    return (
      <ScreenLayout>
        <FormLoading />
      </ScreenLayout>
    );
  }
  return null;
}
