import { useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES, TEMPLATE_ID } from './common/constants';
import useRouter from './hooks/useRouter';
import { AppContextType } from './types/appContext.type';

const PrivateRoute = () => {
  const { getToken } = useContext(AppContext) as AppContextType;
  const idToken = getToken();
  const { params } = useRouter();

  useEffect(() => {
    if (params?.slug) {
      localStorage?.setItem(TEMPLATE_ID, params.slug);
    }
  }, [params]);

  return !idToken ? <Navigate to={ROUTES.LOGIN} /> : <Outlet />;
};
export default PrivateRoute;
