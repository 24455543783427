/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation SignUp($data: SignUpInputData!) {\n  signUp(data: $data) {\n    message\n  }\n}": types.SignUpDocument,
    "\n  mutation LoginUser($data: LoginUserInput!) {\n  loginUser(data: $data) {\n    accessToken\n    user {\n      email\n      phoneNumber\n      subscriptionPlan {\n        features\n      }\n    }\n    refreshToken\n  }\n}": types.LoginUserDocument,
    "\n  mutation ResendVerificationEmail($data: ResendVerificationEmailData!) {\n  resendVerificationEmail(data: $data) {\n    message\n  }\n}": types.ResendVerificationEmailDocument,
    "\n  mutation VerifyEmail($data: VerifyEmailInput) {\n  verifyEmail(data: $data) {\n    message\n    user {\n        email\n        name\n        phoneNumber\n        countryCode\n        profileImage\n        countryCode\n        isNewUser\n        defaultWorkspace {\n          id\n          uuid\n          name\n          workspaceMembers {\n            id\n            role\n          }\n        }\n        subscriptionPlan {\n          features\n        }\n      }\n    accessToken\n    refreshToken\n  }\n}": types.VerifyEmailDocument,
    "\n mutation ResetPassword($data: ResetPasswordInput!) {\n  resetPassword(data: $data) {\n    message\n  }\n}": types.ResetPasswordDocument,
    "\n  mutation ForgotPassword($data: ForgotPasswordInput!) {\n  forgotPassword(data: $data) {\n    message\n  }\n}": types.ForgotPasswordDocument,
    "\n  mutation Logout {\n  logout {\n    message\n  }\n}": types.LogoutDocument,
    "\n  mutation FirebaseLogin($data: FirebaseLoginDataInput) {\n  firebaseLogin(data: $data) {\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n      profileImage\n      countryCode\n      isNewUser\n      defaultWorkspace {\n        id\n        uuid\n        name\n        workspaceMembers {\n          id\n          role\n        }\n      }\n      subscriptionPlan {\n        features\n      }\n    }\n    accessToken\n    refreshToken\n  }\n}": types.FirebaseLoginDocument,
    "\n mutation LoginEmailUser($data: LoginUserInput!) {\n  loginUser(data: $data) {\n    accessToken\n    refreshToken\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n      profileImage\n      countryCode\n      isNewUser\n      emailVerifiedAt\n      defaultWorkspace {\n        id\n        uuid\n        name\n        workspaceMembers {\n          id\n          role\n        }\n      }\n      subscriptionPlan {\n        features\n      }\n    }\n  }\n}": types.LoginEmailUserDocument,
    "\n  mutation RefreshToken($data: RefreshTokenInput!) {\n  refreshToken(data: $data) {\n    accessToken\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n      subscriptionPlan {\n        features\n      }\n    }\n  }\n}": types.RefreshTokenDocument,
    "\n mutation UpdateUserProfile($data: UserProfileData!) {\n  updateUserProfile(data: $data) {\n    message\n    user {\n      profileImage\n    }\n  }\n}": types.UpdateUserProfileDocument,
    "\n query CurrentUser {\n  currentUser {\n    companyName\n    email\n    phoneNumber\n    name\n    roles\n    profileImage\n    isNewUser\n    countryCode\n    defaultWorkspace {\n      id\n      uuid\n      name\n      storageUsed\n      workspaceMembers {\n        role\n        id\n      }\n    }\n    subscriptionPlan {\n      features\n    }\n  }\n}\n": types.CurrentUserDocument,
    "\n  query CurrentUserRole {\n   currentUser {\n     defaultWorkspace {\n       workspaceMembers {\n         role\n         id\n       }\n     }\n   }\n }\n ": types.CurrentUserRoleDocument,
    "\n  query Query($data: IsPhoneNumberInUseInput!) {\n  isPhoneNumberInUse(data: $data) {\n    isPhoneNumberInUsed\n  }\n}": types.QueryDocument,
    "\nmutation CreateForm($data: CreateFormInput!) {\n  createForm(data: $data) {\n    data {\n      id\n    }\n  }\n}": types.CreateFormDocument,
    "\nmutation AddFormElements($where: FormUniqueInput!, $data: AddFormElementInput!) {\n  addFormElements(where: $where, data: $data) {\n    data {\n      structure\n    }\n  }\n}": types.AddFormElementsDocument,
    "\n mutation GenerateFormStructure($data: FormUniqueInput!) {\n  generateFormStructure(data: $data) {\n    data {\n      id\n      status\n      isUpdated\n      title\n      previewKey\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n        closingMessage\n      }\n    }\n  }\n}": types.GenerateFormStructureDocument,
    "\nmutation UpdateFormStructure($where: FormUniqueInput!, $data: UpdateFormStructureInput!) {\n  updateFormStructure(where: $where, data: $data) {\n    data {\n      id\n      status\n      isUpdated\n      previewKey\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n        closingMessage\n      }\n    }\n  }\n}": types.UpdateFormStructureDocument,
    "\nmutation UpdateFormDesign($where: FormUniqueInput!, $data: UpdateFormDesignInput!) {\n  updateFormDesign(where: $where, data: $data) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}": types.UpdateFormDesignDocument,
    "\nmutation UpdateFormSetting($where: FormUniqueInput!, $data: UpdateFormSettingInput!) {\n  updateFormSetting(where: $where, data: $data) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}": types.UpdateFormSettingDocument,
    "\n mutation PublishForm($where: FormUniqueInput!) {\n    publishForm(where: $where) {\n      message\n      data {\n        previewKey\n        status\n        isUpdated\n      }\n    }\n}": types.PublishFormDocument,
    "\n  mutation UpdateForm($where: FormUniqueInput!, $data: UpdateFormInput!) {\n    updateForm(where: $where, data: $data) {\n      message\n      data {\n        title\n      }\n    }\n}": types.UpdateFormDocument,
    "\n  mutation UpdateFormShare($data: FormShareUpdateInput!, $where: FormShareUniqueInput!) {\n  updateFormShare(data: $data, where: $where) {\n    data {\n      id\n    }\n  }\n}": types.UpdateFormShareDocument,
    "\n  mutation UpdateFormTemplate($where: FormUniqueInput!, $data: UpdateFormTemplateDataInput!) {\n  updateFormTemplate(where: $where, data: $data) {\n    message\n  }\n}": types.UpdateFormTemplateDocument,
    "\nmutation UnpublishForm($where: FormUniqueInput!, $data: UnpublishFormData) {\n  unpublishForm(where: $where, data: $data) {\n    data {\n      status\n    }\n    message\n  }\n}": types.UnpublishFormDocument,
    "\nmutation UseFormTemplate($data: UseTemplateUniqueSlugInput!) {\n  useFormTemplate(data: $data) {\n    data {\n      id\n    }\n  }\n}": types.UseFormTemplateDocument,
    "\n mutation UpdateDraftForm($where: FormUniqueInput!) {\n  updateDraftForm(where: $where) {\n    updated\n  }\n}": types.UpdateDraftFormDocument,
    "\n mutation CreateFormShare($data: FormShareCreationInput!, $where: FormUniqueInput!) {\n  createFormShare(data: $data, where: $where) {\n    message\n    data {\n      id\n    }\n  }\n}": types.CreateFormShareDocument,
    "\n mutation DeleteFormShare($where: FormShareUniqueInput!) {\n  deleteFormShare(where: $where) {\n    message\n  }\n}": types.DeleteFormShareDocument,
    "\nquery Form($where: FormUniqueInput!) {\n  form(where: $where) {\n    data {\n      title\n      prompt\n      id\n      previewKey\n      status\n      isUpdated\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n        removeBranding\n      }\n      settings {\n        tone\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n        closingMessage\n        tracking\n      }\n    }\n  }\n}": types.FormDocument,
    "\nquery FormThemes($data: FormUniqueInput!) {\n  formThemes(data: $data) {\n    data {\n      id\n      title\n      configs\n      isUsed\n      type\n      themeVariations {\n        id\n        colorPalette\n        isUsed\n        title\n        type\n      }\n    }\n  }\n}": types.FormThemesDocument,
    "\n  query GetForm($where: GetFormWhereInput!) {\n  getForm(where: $where) {\n    data {\n      title\n      id\n      designs {\n        id\n        theme {\n          configs\n        }\n        themeVariation {\n          colorPalette\n          type\n        }\n        themeMode\n      }\n      shares {\n        id\n        formId\n        formType\n        key\n        title\n        embedConfigs\n        ogTitle\n        type\n        ogDescription\n        ogThumbnail\n        ogThumbnailUrl\n      }\n      structures {\n        structure\n      }\n    }\n  }\n}": types.GetFormDocument,
    "\n  query GetFormStructurePagesImageUrl($data: GetFormStructureImageUrlInput!) {\n  getFormStructurePagesImageUrl(data: $data) {\n    imageUrl\n  }\n}": types.GetFormStructurePagesImageUrlDocument,
    "\n  query FormSubmission($where: SubmissionUniqueInput!) {\n  formSubmission(where: $where) {\n    data {\n      isCompleted\n    }\n  }\n}": types.FormSubmissionDocument,
    "\n  query SubmissionTranscripts($filter: ListSubmissionTranscriptsFilter!, $where: SubmissionUniqueInput!, $sort: [ListSubmissionTranscriptsSort!]) {\n  submissionTranscripts(filter: $filter, where: $where, sort: $sort) {\n    data {\n      configs\n      userResponse{\n        value\n      }\n      aiResponse\n      options\n      type\n    }\n    count\n  }\n}": types.SubmissionTranscriptsDocument,
    "\n  query InitiateVoiceFormSubmission($data: InitiateVoiceFormSubmissionInput!) {\n  initiateVoiceFormSubmission(data: $data) {\n    message\n  }\n}": types.InitiateVoiceFormSubmissionDocument,
    "\n  query FormShares($where: FormUniqueInput!, $filter: ListFormShareFilter, $sort: [ListFormShareSort!]) {\n  formShares(where: $where, filter: $filter, sort: $sort) {\n    count\n    data {\n      formType\n      type\n      title\n      key\n      id\n      formId\n      embedConfigs\n    }\n  }\n}": types.FormSharesDocument,
    "\n  query FormShare($where: FormShareUniqueInput!) {\n  formShare(where: $where) {\n    count\n    data {\n      id\n      type\n      key\n      ogDescription\n      ogThumbnail\n      ogThumbnailUrl\n      ogTitle\n      title\n      formId\n      formType\n      embedConfigs\n    }\n  }\n}": types.FormShareDocument,
    "\n  query FormTemplateAdmin($where: FormUniqueInput!) {\n  formTemplateAdmin(where: $where) {\n    id\n    categoryId\n    category {\n      slug\n      name\n      id\n    }\n    displayConfig\n    slug\n    weightage\n    description\n    tags\n  }\n}": types.FormTemplateAdminDocument,
    "\nquery FormStructure($where: FormUniqueInput!) {\n  form(where: $where) {\n    data {\n      structures {\n        structure\n      }\n    }\n  }\n}": types.FormStructureDocument,
    "\n  query GetFormPageImageUploadSignedUrl($data: GetFormPageImageUploadSignedUrlInput!) {\n  getFormPageImageUploadSignedUrl(data: $data) {\n    signedUrl\n    key\n  }\n}": types.GetFormPageImageUploadSignedUrlDocument,
    "\n  query GetFormOgImageUploadSignedUrl($data: GetFormOgImageUploadSignedUrlInput!) {\n  getFormOgImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }\n}": types.GetFormOgImageUploadSignedUrlDocument,
    "\n  query GetSubmissionFilesUploadSignedUrl($data: GetFormFilesUploadSignedUrlInput!) {\n  getSubmissionFilesUploadSignedUrl(data: $data) {\n    signedUrls {\n      key\n      signedUrl\n    }\n  }\n}": types.GetSubmissionFilesUploadSignedUrlDocument,
    "\n  query FormSubmissionOverview($filter: FormSubmissionOverview!, $where: FormUniqueInput!) {\n  formSubmissionOverview(filter: $filter, where: $where) {\n    data {\n      completedSubmissions\n      averageSubmissionTime\n      incompleteSubmissions\n    }\n  }\n}": types.FormSubmissionOverviewDocument,
    "\n  query FormSubmissionsByQuestion($filter: ListFormSubmissionsByQuestionFilter!, $where: FormUniqueInput!) {\n  formSubmissionsByQuestion(filter: $filter, where: $where) {\n    data {\n      questionResults {\n        data {\n          names\n          value\n          name\n          count\n        }\n        key\n        type\n      }\n      totalSubmissions\n      questions\n    }\n  }\n}": types.FormSubmissionsByQuestionDocument,
    "\n query FormSubmissions($filter: ListFormSubmissionsFilter!, $where: FormUniqueInput!, $sort: [ListFormSubmissionsSort!]) {\n  formSubmissions(filter: $filter, where: $where, sort: $sort) {\n    count\n    data {\n      formStructure\n      submissions {\n        submittedData\n        createdAt\n        id\n        type\n        metadata\n        voiceConversation {\n          aiResponse\n          userResponse {\n            value\n          }\n        }\n      }\n    }\n  }\n}": types.FormSubmissionsDocument,
    "\nquery FormCategories($filter: ListFormCategoriesFilter, $sort: [ListFormCategoriesSort!]) {\n  formCategories(filter: $filter, sort: $sort) {\n    data {\n      id\n      name\n      formTemplates {\n        slug\n        form {\n          title\n          prompt\n          designs {\n            themeMode\n            themeVariation {\n              id\n              title\n              type\n              colorPalette\n              isUsed\n            }\n          }\n        }\n      }\n    }\n  }\n}": types.FormCategoriesDocument,
    "\n mutation DeleteForm($where: FormUniqueInput!) {\n  deleteForm(where: $where) {\n    message\n  }\n}": types.DeleteFormDocument,
    "\nmutation DuplicateForm($where: FormUniqueInput!) {\n  duplicateForm(where: $where) {\n    message\n  }\n}": types.DuplicateFormDocument,
    "\n query Forms($filter: ListFormFilter, $sort: [ListFormSort!]) {\n  forms(filter: $filter, sort: $sort) {\n    count\n    data {\n      designs {\n        theme {\n          type\n          configs\n        }\n        themeVariation {\n          type\n          colorPalette\n        }\n        themeMode\n      }\n      status\n      totalSubmissionsCount\n      notViewedSubmissionsCount\n      id\n      title\n    }\n  }\n}": types.FormsDocument,
    "\n mutation DeleteAccount {\n  deleteAccount {\n    message\n  }\n}": types.DeleteAccountDocument,
    "\n  mutation ActivateSubscriptionPlan($data: SubscriptionPlanUniqueInput!) {\n  activateSubscriptionPlan(data: $data) {\n    message\n    data {\n      features\n    }\n  }\n}": types.ActivateSubscriptionPlanDocument,
    "\n query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {\n  getProfileImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }}": types.GetProfileImageUploadSignedUrlDocument,
    "\n   query getPlanList($sort: [ListSubscriptionPlanSort!]) {\n  subscriptionPlanKeyFeatures(sort: $sort) {\n    data {\n      id\n      keyFeatures\n      description\n      price\n      currency\n      type\n      title\n      label\n      recurring\n      isSubscribed\n      slug\n    }\n  }\n}": types.GetPlanListDocument,
    "\n  query SubscriptionPlans($sort: [ListSubscriptionPlanSort!]) {\n  subscriptionPlans(sort: $sort) {\n    data {\n      id\n      label\n      isSubscribed\n      features\n      currency\n      price\n      categoryWiseGroupedFeatures {\n        categoryKey\n        list {\n          title\n          subCategoryKey\n          features {\n            value\n            title\n            key\n            featureLabel\n            description\n            isVisible\n            comingSoon\n          }\n        }\n        title\n      }\n    }\n  }\n}": types.SubscriptionPlansDocument,
    "\nmutation CreateWorkspace($data: CreateWorkspaceInput!) {\n  createWorkspace(data: $data) {\n    message\n    data {\n      id\n      uuid\n      name\n    }\n  }\n}": types.CreateWorkspaceDocument,
    "\nmutation UpdateWorkspace($data: UpdateWorkspaceInput!, $where: WorkspaceUniqueUuidInput!) {\n  updateWorkspace(data: $data, where: $where) {\n    message\n  }\n}": types.UpdateWorkspaceDocument,
    "\n  mutation DeleteWorkspace($where: WorkspaceUniqueUuidInput!) {\n  deleteWorkspace(where: $where) {\n    message\n  }\n}": types.DeleteWorkspaceDocument,
    "\n  mutation InviteUserInWorkspace($data: InviteWorkspaceMembersInput!, $where: WorkspaceUniqueUuidInput!) {\n  inviteUserInWorkspace(data: $data, where: $where) {\n    message\n  }\n}": types.InviteUserInWorkspaceDocument,
    "\n  mutation DeleteMember($where: WorkspaceMemberUniqueInput!) {\n  deleteMember(where: $where) {\n    message\n  }\n}": types.DeleteMemberDocument,
    "\n mutation UpdateMemberRole($where: WorkspaceMemberUniqueInput!, $data: UpdateMemberRoleInput!) {\n  updateMemberRole(where: $where, data: $data) {\n    message\n  }\n}": types.UpdateMemberRoleDocument,
    "\n mutation ResendInvitation($data: ResendInvitationInput!) {\n  resendInvitation(data: $data) {\n    message\n  }\n}": types.ResendInvitationDocument,
    "\n mutation AcceptInvitation($data: AcceptInvitationInput!) {\n  acceptInvitation(data: $data) {\n    message\n  }\n}": types.AcceptInvitationDocument,
    "\n  query Workspaces($filter: ListWorkspaceFilter, $sort: [ListWorkspaceSort!]) {\n  workspaces(filter: $filter, sort: $sort) {\n    count\n    data {\n      id\n      name\n      uuid\n      workspaceMembers {\n        id\n        role\n      }\n    }\n  }}": types.WorkspacesDocument,
    "\n  query Workspace($where: WorkspaceUniqueUuidInput!) {\n    workspace(where: $where) {\n      data {\n        name\n        uuid\n        default\n        workspaceMembers {\n          role\n        }\n      }\n    }\n}": types.WorkspaceDocument,
    "\n  query WorkspaceMembers($filter: ListWorkspaceMembersFilter, $sort: [ListWorkspaceMembersSort!], $where: WorkspaceUniqueUuidInput!) {\n    workspaceMembers(filter: $filter, sort: $sort, where: $where) {\n      count\n      totalMembers\n      subscriptionPlan {\n        features\n      }\n      data {\n        member {\n          email\n          name\n        }\n        role\n        status\n        email\n        id\n      }\n    }\n}": types.WorkspaceMembersDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SignUp($data: SignUpInputData!) {\n  signUp(data: $data) {\n    message\n  }\n}"): (typeof documents)["\n  mutation SignUp($data: SignUpInputData!) {\n  signUp(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation LoginUser($data: LoginUserInput!) {\n  loginUser(data: $data) {\n    accessToken\n    user {\n      email\n      phoneNumber\n      subscriptionPlan {\n        features\n      }\n    }\n    refreshToken\n  }\n}"): (typeof documents)["\n  mutation LoginUser($data: LoginUserInput!) {\n  loginUser(data: $data) {\n    accessToken\n    user {\n      email\n      phoneNumber\n      subscriptionPlan {\n        features\n      }\n    }\n    refreshToken\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ResendVerificationEmail($data: ResendVerificationEmailData!) {\n  resendVerificationEmail(data: $data) {\n    message\n  }\n}"): (typeof documents)["\n  mutation ResendVerificationEmail($data: ResendVerificationEmailData!) {\n  resendVerificationEmail(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation VerifyEmail($data: VerifyEmailInput) {\n  verifyEmail(data: $data) {\n    message\n    user {\n        email\n        name\n        phoneNumber\n        countryCode\n        profileImage\n        countryCode\n        isNewUser\n        defaultWorkspace {\n          id\n          uuid\n          name\n          workspaceMembers {\n            id\n            role\n          }\n        }\n        subscriptionPlan {\n          features\n        }\n      }\n    accessToken\n    refreshToken\n  }\n}"): (typeof documents)["\n  mutation VerifyEmail($data: VerifyEmailInput) {\n  verifyEmail(data: $data) {\n    message\n    user {\n        email\n        name\n        phoneNumber\n        countryCode\n        profileImage\n        countryCode\n        isNewUser\n        defaultWorkspace {\n          id\n          uuid\n          name\n          workspaceMembers {\n            id\n            role\n          }\n        }\n        subscriptionPlan {\n          features\n        }\n      }\n    accessToken\n    refreshToken\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation ResetPassword($data: ResetPasswordInput!) {\n  resetPassword(data: $data) {\n    message\n  }\n}"): (typeof documents)["\n mutation ResetPassword($data: ResetPasswordInput!) {\n  resetPassword(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ForgotPassword($data: ForgotPasswordInput!) {\n  forgotPassword(data: $data) {\n    message\n  }\n}"): (typeof documents)["\n  mutation ForgotPassword($data: ForgotPasswordInput!) {\n  forgotPassword(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Logout {\n  logout {\n    message\n  }\n}"): (typeof documents)["\n  mutation Logout {\n  logout {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation FirebaseLogin($data: FirebaseLoginDataInput) {\n  firebaseLogin(data: $data) {\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n      profileImage\n      countryCode\n      isNewUser\n      defaultWorkspace {\n        id\n        uuid\n        name\n        workspaceMembers {\n          id\n          role\n        }\n      }\n      subscriptionPlan {\n        features\n      }\n    }\n    accessToken\n    refreshToken\n  }\n}"): (typeof documents)["\n  mutation FirebaseLogin($data: FirebaseLoginDataInput) {\n  firebaseLogin(data: $data) {\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n      profileImage\n      countryCode\n      isNewUser\n      defaultWorkspace {\n        id\n        uuid\n        name\n        workspaceMembers {\n          id\n          role\n        }\n      }\n      subscriptionPlan {\n        features\n      }\n    }\n    accessToken\n    refreshToken\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation LoginEmailUser($data: LoginUserInput!) {\n  loginUser(data: $data) {\n    accessToken\n    refreshToken\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n      profileImage\n      countryCode\n      isNewUser\n      emailVerifiedAt\n      defaultWorkspace {\n        id\n        uuid\n        name\n        workspaceMembers {\n          id\n          role\n        }\n      }\n      subscriptionPlan {\n        features\n      }\n    }\n  }\n}"): (typeof documents)["\n mutation LoginEmailUser($data: LoginUserInput!) {\n  loginUser(data: $data) {\n    accessToken\n    refreshToken\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n      profileImage\n      countryCode\n      isNewUser\n      emailVerifiedAt\n      defaultWorkspace {\n        id\n        uuid\n        name\n        workspaceMembers {\n          id\n          role\n        }\n      }\n      subscriptionPlan {\n        features\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RefreshToken($data: RefreshTokenInput!) {\n  refreshToken(data: $data) {\n    accessToken\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n      subscriptionPlan {\n        features\n      }\n    }\n  }\n}"): (typeof documents)["\n  mutation RefreshToken($data: RefreshTokenInput!) {\n  refreshToken(data: $data) {\n    accessToken\n    user {\n      email\n      name\n      phoneNumber\n      countryCode\n      subscriptionPlan {\n        features\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation UpdateUserProfile($data: UserProfileData!) {\n  updateUserProfile(data: $data) {\n    message\n    user {\n      profileImage\n    }\n  }\n}"): (typeof documents)["\n mutation UpdateUserProfile($data: UserProfileData!) {\n  updateUserProfile(data: $data) {\n    message\n    user {\n      profileImage\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n query CurrentUser {\n  currentUser {\n    companyName\n    email\n    phoneNumber\n    name\n    roles\n    profileImage\n    isNewUser\n    countryCode\n    defaultWorkspace {\n      id\n      uuid\n      name\n      storageUsed\n      workspaceMembers {\n        role\n        id\n      }\n    }\n    subscriptionPlan {\n      features\n    }\n  }\n}\n"): (typeof documents)["\n query CurrentUser {\n  currentUser {\n    companyName\n    email\n    phoneNumber\n    name\n    roles\n    profileImage\n    isNewUser\n    countryCode\n    defaultWorkspace {\n      id\n      uuid\n      name\n      storageUsed\n      workspaceMembers {\n        role\n        id\n      }\n    }\n    subscriptionPlan {\n      features\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query CurrentUserRole {\n   currentUser {\n     defaultWorkspace {\n       workspaceMembers {\n         role\n         id\n       }\n     }\n   }\n }\n "): (typeof documents)["\n  query CurrentUserRole {\n   currentUser {\n     defaultWorkspace {\n       workspaceMembers {\n         role\n         id\n       }\n     }\n   }\n }\n "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Query($data: IsPhoneNumberInUseInput!) {\n  isPhoneNumberInUse(data: $data) {\n    isPhoneNumberInUsed\n  }\n}"): (typeof documents)["\n  query Query($data: IsPhoneNumberInUseInput!) {\n  isPhoneNumberInUse(data: $data) {\n    isPhoneNumberInUsed\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation CreateForm($data: CreateFormInput!) {\n  createForm(data: $data) {\n    data {\n      id\n    }\n  }\n}"): (typeof documents)["\nmutation CreateForm($data: CreateFormInput!) {\n  createForm(data: $data) {\n    data {\n      id\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddFormElements($where: FormUniqueInput!, $data: AddFormElementInput!) {\n  addFormElements(where: $where, data: $data) {\n    data {\n      structure\n    }\n  }\n}"): (typeof documents)["\nmutation AddFormElements($where: FormUniqueInput!, $data: AddFormElementInput!) {\n  addFormElements(where: $where, data: $data) {\n    data {\n      structure\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation GenerateFormStructure($data: FormUniqueInput!) {\n  generateFormStructure(data: $data) {\n    data {\n      id\n      status\n      isUpdated\n      title\n      previewKey\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n        closingMessage\n      }\n    }\n  }\n}"): (typeof documents)["\n mutation GenerateFormStructure($data: FormUniqueInput!) {\n  generateFormStructure(data: $data) {\n    data {\n      id\n      status\n      isUpdated\n      title\n      previewKey\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n        closingMessage\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateFormStructure($where: FormUniqueInput!, $data: UpdateFormStructureInput!) {\n  updateFormStructure(where: $where, data: $data) {\n    data {\n      id\n      status\n      isUpdated\n      previewKey\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n        closingMessage\n      }\n    }\n  }\n}"): (typeof documents)["\nmutation UpdateFormStructure($where: FormUniqueInput!, $data: UpdateFormStructureInput!) {\n  updateFormStructure(where: $where, data: $data) {\n    data {\n      id\n      status\n      isUpdated\n      previewKey\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n      }\n      settings {\n        tone\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n        closingMessage\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateFormDesign($where: FormUniqueInput!, $data: UpdateFormDesignInput!) {\n  updateFormDesign(where: $where, data: $data) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}"): (typeof documents)["\nmutation UpdateFormDesign($where: FormUniqueInput!, $data: UpdateFormDesignInput!) {\n  updateFormDesign(where: $where, data: $data) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateFormSetting($where: FormUniqueInput!, $data: UpdateFormSettingInput!) {\n  updateFormSetting(where: $where, data: $data) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}"): (typeof documents)["\nmutation UpdateFormSetting($where: FormUniqueInput!, $data: UpdateFormSettingInput!) {\n  updateFormSetting(where: $where, data: $data) {\n    message\n    data {\n      previewKey\n      status\n      isUpdated\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation PublishForm($where: FormUniqueInput!) {\n    publishForm(where: $where) {\n      message\n      data {\n        previewKey\n        status\n        isUpdated\n      }\n    }\n}"): (typeof documents)["\n mutation PublishForm($where: FormUniqueInput!) {\n    publishForm(where: $where) {\n      message\n      data {\n        previewKey\n        status\n        isUpdated\n      }\n    }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateForm($where: FormUniqueInput!, $data: UpdateFormInput!) {\n    updateForm(where: $where, data: $data) {\n      message\n      data {\n        title\n      }\n    }\n}"): (typeof documents)["\n  mutation UpdateForm($where: FormUniqueInput!, $data: UpdateFormInput!) {\n    updateForm(where: $where, data: $data) {\n      message\n      data {\n        title\n      }\n    }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateFormShare($data: FormShareUpdateInput!, $where: FormShareUniqueInput!) {\n  updateFormShare(data: $data, where: $where) {\n    data {\n      id\n    }\n  }\n}"): (typeof documents)["\n  mutation UpdateFormShare($data: FormShareUpdateInput!, $where: FormShareUniqueInput!) {\n  updateFormShare(data: $data, where: $where) {\n    data {\n      id\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateFormTemplate($where: FormUniqueInput!, $data: UpdateFormTemplateDataInput!) {\n  updateFormTemplate(where: $where, data: $data) {\n    message\n  }\n}"): (typeof documents)["\n  mutation UpdateFormTemplate($where: FormUniqueInput!, $data: UpdateFormTemplateDataInput!) {\n  updateFormTemplate(where: $where, data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UnpublishForm($where: FormUniqueInput!, $data: UnpublishFormData) {\n  unpublishForm(where: $where, data: $data) {\n    data {\n      status\n    }\n    message\n  }\n}"): (typeof documents)["\nmutation UnpublishForm($where: FormUniqueInput!, $data: UnpublishFormData) {\n  unpublishForm(where: $where, data: $data) {\n    data {\n      status\n    }\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UseFormTemplate($data: UseTemplateUniqueSlugInput!) {\n  useFormTemplate(data: $data) {\n    data {\n      id\n    }\n  }\n}"): (typeof documents)["\nmutation UseFormTemplate($data: UseTemplateUniqueSlugInput!) {\n  useFormTemplate(data: $data) {\n    data {\n      id\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation UpdateDraftForm($where: FormUniqueInput!) {\n  updateDraftForm(where: $where) {\n    updated\n  }\n}"): (typeof documents)["\n mutation UpdateDraftForm($where: FormUniqueInput!) {\n  updateDraftForm(where: $where) {\n    updated\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation CreateFormShare($data: FormShareCreationInput!, $where: FormUniqueInput!) {\n  createFormShare(data: $data, where: $where) {\n    message\n    data {\n      id\n    }\n  }\n}"): (typeof documents)["\n mutation CreateFormShare($data: FormShareCreationInput!, $where: FormUniqueInput!) {\n  createFormShare(data: $data, where: $where) {\n    message\n    data {\n      id\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation DeleteFormShare($where: FormShareUniqueInput!) {\n  deleteFormShare(where: $where) {\n    message\n  }\n}"): (typeof documents)["\n mutation DeleteFormShare($where: FormShareUniqueInput!) {\n  deleteFormShare(where: $where) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery Form($where: FormUniqueInput!) {\n  form(where: $where) {\n    data {\n      title\n      prompt\n      id\n      previewKey\n      status\n      isUpdated\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n        removeBranding\n      }\n      settings {\n        tone\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n        closingMessage\n        tracking\n      }\n    }\n  }\n}"): (typeof documents)["\nquery Form($where: FormUniqueInput!) {\n  form(where: $where) {\n    data {\n      title\n      prompt\n      id\n      previewKey\n      status\n      isUpdated\n      structures {\n        overview\n        structure\n      }\n      designs {\n        themeId\n        themeMode\n        themeVariationId\n        removeBranding\n      }\n      settings {\n        tone\n        finalPreview\n        notifyOn\n        notifyAtDay\n        notifyAtSubmission\n        closingMessage\n        tracking\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery FormThemes($data: FormUniqueInput!) {\n  formThemes(data: $data) {\n    data {\n      id\n      title\n      configs\n      isUsed\n      type\n      themeVariations {\n        id\n        colorPalette\n        isUsed\n        title\n        type\n      }\n    }\n  }\n}"): (typeof documents)["\nquery FormThemes($data: FormUniqueInput!) {\n  formThemes(data: $data) {\n    data {\n      id\n      title\n      configs\n      isUsed\n      type\n      themeVariations {\n        id\n        colorPalette\n        isUsed\n        title\n        type\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetForm($where: GetFormWhereInput!) {\n  getForm(where: $where) {\n    data {\n      title\n      id\n      designs {\n        id\n        theme {\n          configs\n        }\n        themeVariation {\n          colorPalette\n          type\n        }\n        themeMode\n      }\n      shares {\n        id\n        formId\n        formType\n        key\n        title\n        embedConfigs\n        ogTitle\n        type\n        ogDescription\n        ogThumbnail\n        ogThumbnailUrl\n      }\n      structures {\n        structure\n      }\n    }\n  }\n}"): (typeof documents)["\n  query GetForm($where: GetFormWhereInput!) {\n  getForm(where: $where) {\n    data {\n      title\n      id\n      designs {\n        id\n        theme {\n          configs\n        }\n        themeVariation {\n          colorPalette\n          type\n        }\n        themeMode\n      }\n      shares {\n        id\n        formId\n        formType\n        key\n        title\n        embedConfigs\n        ogTitle\n        type\n        ogDescription\n        ogThumbnail\n        ogThumbnailUrl\n      }\n      structures {\n        structure\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFormStructurePagesImageUrl($data: GetFormStructureImageUrlInput!) {\n  getFormStructurePagesImageUrl(data: $data) {\n    imageUrl\n  }\n}"): (typeof documents)["\n  query GetFormStructurePagesImageUrl($data: GetFormStructureImageUrlInput!) {\n  getFormStructurePagesImageUrl(data: $data) {\n    imageUrl\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FormSubmission($where: SubmissionUniqueInput!) {\n  formSubmission(where: $where) {\n    data {\n      isCompleted\n    }\n  }\n}"): (typeof documents)["\n  query FormSubmission($where: SubmissionUniqueInput!) {\n  formSubmission(where: $where) {\n    data {\n      isCompleted\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SubmissionTranscripts($filter: ListSubmissionTranscriptsFilter!, $where: SubmissionUniqueInput!, $sort: [ListSubmissionTranscriptsSort!]) {\n  submissionTranscripts(filter: $filter, where: $where, sort: $sort) {\n    data {\n      configs\n      userResponse{\n        value\n      }\n      aiResponse\n      options\n      type\n    }\n    count\n  }\n}"): (typeof documents)["\n  query SubmissionTranscripts($filter: ListSubmissionTranscriptsFilter!, $where: SubmissionUniqueInput!, $sort: [ListSubmissionTranscriptsSort!]) {\n  submissionTranscripts(filter: $filter, where: $where, sort: $sort) {\n    data {\n      configs\n      userResponse{\n        value\n      }\n      aiResponse\n      options\n      type\n    }\n    count\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query InitiateVoiceFormSubmission($data: InitiateVoiceFormSubmissionInput!) {\n  initiateVoiceFormSubmission(data: $data) {\n    message\n  }\n}"): (typeof documents)["\n  query InitiateVoiceFormSubmission($data: InitiateVoiceFormSubmissionInput!) {\n  initiateVoiceFormSubmission(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FormShares($where: FormUniqueInput!, $filter: ListFormShareFilter, $sort: [ListFormShareSort!]) {\n  formShares(where: $where, filter: $filter, sort: $sort) {\n    count\n    data {\n      formType\n      type\n      title\n      key\n      id\n      formId\n      embedConfigs\n    }\n  }\n}"): (typeof documents)["\n  query FormShares($where: FormUniqueInput!, $filter: ListFormShareFilter, $sort: [ListFormShareSort!]) {\n  formShares(where: $where, filter: $filter, sort: $sort) {\n    count\n    data {\n      formType\n      type\n      title\n      key\n      id\n      formId\n      embedConfigs\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FormShare($where: FormShareUniqueInput!) {\n  formShare(where: $where) {\n    count\n    data {\n      id\n      type\n      key\n      ogDescription\n      ogThumbnail\n      ogThumbnailUrl\n      ogTitle\n      title\n      formId\n      formType\n      embedConfigs\n    }\n  }\n}"): (typeof documents)["\n  query FormShare($where: FormShareUniqueInput!) {\n  formShare(where: $where) {\n    count\n    data {\n      id\n      type\n      key\n      ogDescription\n      ogThumbnail\n      ogThumbnailUrl\n      ogTitle\n      title\n      formId\n      formType\n      embedConfigs\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FormTemplateAdmin($where: FormUniqueInput!) {\n  formTemplateAdmin(where: $where) {\n    id\n    categoryId\n    category {\n      slug\n      name\n      id\n    }\n    displayConfig\n    slug\n    weightage\n    description\n    tags\n  }\n}"): (typeof documents)["\n  query FormTemplateAdmin($where: FormUniqueInput!) {\n  formTemplateAdmin(where: $where) {\n    id\n    categoryId\n    category {\n      slug\n      name\n      id\n    }\n    displayConfig\n    slug\n    weightage\n    description\n    tags\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery FormStructure($where: FormUniqueInput!) {\n  form(where: $where) {\n    data {\n      structures {\n        structure\n      }\n    }\n  }\n}"): (typeof documents)["\nquery FormStructure($where: FormUniqueInput!) {\n  form(where: $where) {\n    data {\n      structures {\n        structure\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFormPageImageUploadSignedUrl($data: GetFormPageImageUploadSignedUrlInput!) {\n  getFormPageImageUploadSignedUrl(data: $data) {\n    signedUrl\n    key\n  }\n}"): (typeof documents)["\n  query GetFormPageImageUploadSignedUrl($data: GetFormPageImageUploadSignedUrlInput!) {\n  getFormPageImageUploadSignedUrl(data: $data) {\n    signedUrl\n    key\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetFormOgImageUploadSignedUrl($data: GetFormOgImageUploadSignedUrlInput!) {\n  getFormOgImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }\n}"): (typeof documents)["\n  query GetFormOgImageUploadSignedUrl($data: GetFormOgImageUploadSignedUrlInput!) {\n  getFormOgImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetSubmissionFilesUploadSignedUrl($data: GetFormFilesUploadSignedUrlInput!) {\n  getSubmissionFilesUploadSignedUrl(data: $data) {\n    signedUrls {\n      key\n      signedUrl\n    }\n  }\n}"): (typeof documents)["\n  query GetSubmissionFilesUploadSignedUrl($data: GetFormFilesUploadSignedUrlInput!) {\n  getSubmissionFilesUploadSignedUrl(data: $data) {\n    signedUrls {\n      key\n      signedUrl\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FormSubmissionOverview($filter: FormSubmissionOverview!, $where: FormUniqueInput!) {\n  formSubmissionOverview(filter: $filter, where: $where) {\n    data {\n      completedSubmissions\n      averageSubmissionTime\n      incompleteSubmissions\n    }\n  }\n}"): (typeof documents)["\n  query FormSubmissionOverview($filter: FormSubmissionOverview!, $where: FormUniqueInput!) {\n  formSubmissionOverview(filter: $filter, where: $where) {\n    data {\n      completedSubmissions\n      averageSubmissionTime\n      incompleteSubmissions\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FormSubmissionsByQuestion($filter: ListFormSubmissionsByQuestionFilter!, $where: FormUniqueInput!) {\n  formSubmissionsByQuestion(filter: $filter, where: $where) {\n    data {\n      questionResults {\n        data {\n          names\n          value\n          name\n          count\n        }\n        key\n        type\n      }\n      totalSubmissions\n      questions\n    }\n  }\n}"): (typeof documents)["\n  query FormSubmissionsByQuestion($filter: ListFormSubmissionsByQuestionFilter!, $where: FormUniqueInput!) {\n  formSubmissionsByQuestion(filter: $filter, where: $where) {\n    data {\n      questionResults {\n        data {\n          names\n          value\n          name\n          count\n        }\n        key\n        type\n      }\n      totalSubmissions\n      questions\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n query FormSubmissions($filter: ListFormSubmissionsFilter!, $where: FormUniqueInput!, $sort: [ListFormSubmissionsSort!]) {\n  formSubmissions(filter: $filter, where: $where, sort: $sort) {\n    count\n    data {\n      formStructure\n      submissions {\n        submittedData\n        createdAt\n        id\n        type\n        metadata\n        voiceConversation {\n          aiResponse\n          userResponse {\n            value\n          }\n        }\n      }\n    }\n  }\n}"): (typeof documents)["\n query FormSubmissions($filter: ListFormSubmissionsFilter!, $where: FormUniqueInput!, $sort: [ListFormSubmissionsSort!]) {\n  formSubmissions(filter: $filter, where: $where, sort: $sort) {\n    count\n    data {\n      formStructure\n      submissions {\n        submittedData\n        createdAt\n        id\n        type\n        metadata\n        voiceConversation {\n          aiResponse\n          userResponse {\n            value\n          }\n        }\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery FormCategories($filter: ListFormCategoriesFilter, $sort: [ListFormCategoriesSort!]) {\n  formCategories(filter: $filter, sort: $sort) {\n    data {\n      id\n      name\n      formTemplates {\n        slug\n        form {\n          title\n          prompt\n          designs {\n            themeMode\n            themeVariation {\n              id\n              title\n              type\n              colorPalette\n              isUsed\n            }\n          }\n        }\n      }\n    }\n  }\n}"): (typeof documents)["\nquery FormCategories($filter: ListFormCategoriesFilter, $sort: [ListFormCategoriesSort!]) {\n  formCategories(filter: $filter, sort: $sort) {\n    data {\n      id\n      name\n      formTemplates {\n        slug\n        form {\n          title\n          prompt\n          designs {\n            themeMode\n            themeVariation {\n              id\n              title\n              type\n              colorPalette\n              isUsed\n            }\n          }\n        }\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation DeleteForm($where: FormUniqueInput!) {\n  deleteForm(where: $where) {\n    message\n  }\n}"): (typeof documents)["\n mutation DeleteForm($where: FormUniqueInput!) {\n  deleteForm(where: $where) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation DuplicateForm($where: FormUniqueInput!) {\n  duplicateForm(where: $where) {\n    message\n  }\n}"): (typeof documents)["\nmutation DuplicateForm($where: FormUniqueInput!) {\n  duplicateForm(where: $where) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n query Forms($filter: ListFormFilter, $sort: [ListFormSort!]) {\n  forms(filter: $filter, sort: $sort) {\n    count\n    data {\n      designs {\n        theme {\n          type\n          configs\n        }\n        themeVariation {\n          type\n          colorPalette\n        }\n        themeMode\n      }\n      status\n      totalSubmissionsCount\n      notViewedSubmissionsCount\n      id\n      title\n    }\n  }\n}"): (typeof documents)["\n query Forms($filter: ListFormFilter, $sort: [ListFormSort!]) {\n  forms(filter: $filter, sort: $sort) {\n    count\n    data {\n      designs {\n        theme {\n          type\n          configs\n        }\n        themeVariation {\n          type\n          colorPalette\n        }\n        themeMode\n      }\n      status\n      totalSubmissionsCount\n      notViewedSubmissionsCount\n      id\n      title\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation DeleteAccount {\n  deleteAccount {\n    message\n  }\n}"): (typeof documents)["\n mutation DeleteAccount {\n  deleteAccount {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ActivateSubscriptionPlan($data: SubscriptionPlanUniqueInput!) {\n  activateSubscriptionPlan(data: $data) {\n    message\n    data {\n      features\n    }\n  }\n}"): (typeof documents)["\n  mutation ActivateSubscriptionPlan($data: SubscriptionPlanUniqueInput!) {\n  activateSubscriptionPlan(data: $data) {\n    message\n    data {\n      features\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {\n  getProfileImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }}"): (typeof documents)["\n query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {\n  getProfileImageUploadSignedUrl(data: $data) {\n    key\n    signedUrl\n  }}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n   query getPlanList($sort: [ListSubscriptionPlanSort!]) {\n  subscriptionPlanKeyFeatures(sort: $sort) {\n    data {\n      id\n      keyFeatures\n      description\n      price\n      currency\n      type\n      title\n      label\n      recurring\n      isSubscribed\n      slug\n    }\n  }\n}"): (typeof documents)["\n   query getPlanList($sort: [ListSubscriptionPlanSort!]) {\n  subscriptionPlanKeyFeatures(sort: $sort) {\n    data {\n      id\n      keyFeatures\n      description\n      price\n      currency\n      type\n      title\n      label\n      recurring\n      isSubscribed\n      slug\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SubscriptionPlans($sort: [ListSubscriptionPlanSort!]) {\n  subscriptionPlans(sort: $sort) {\n    data {\n      id\n      label\n      isSubscribed\n      features\n      currency\n      price\n      categoryWiseGroupedFeatures {\n        categoryKey\n        list {\n          title\n          subCategoryKey\n          features {\n            value\n            title\n            key\n            featureLabel\n            description\n            isVisible\n            comingSoon\n          }\n        }\n        title\n      }\n    }\n  }\n}"): (typeof documents)["\n  query SubscriptionPlans($sort: [ListSubscriptionPlanSort!]) {\n  subscriptionPlans(sort: $sort) {\n    data {\n      id\n      label\n      isSubscribed\n      features\n      currency\n      price\n      categoryWiseGroupedFeatures {\n        categoryKey\n        list {\n          title\n          subCategoryKey\n          features {\n            value\n            title\n            key\n            featureLabel\n            description\n            isVisible\n            comingSoon\n          }\n        }\n        title\n      }\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation CreateWorkspace($data: CreateWorkspaceInput!) {\n  createWorkspace(data: $data) {\n    message\n    data {\n      id\n      uuid\n      name\n    }\n  }\n}"): (typeof documents)["\nmutation CreateWorkspace($data: CreateWorkspaceInput!) {\n  createWorkspace(data: $data) {\n    message\n    data {\n      id\n      uuid\n      name\n    }\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateWorkspace($data: UpdateWorkspaceInput!, $where: WorkspaceUniqueUuidInput!) {\n  updateWorkspace(data: $data, where: $where) {\n    message\n  }\n}"): (typeof documents)["\nmutation UpdateWorkspace($data: UpdateWorkspaceInput!, $where: WorkspaceUniqueUuidInput!) {\n  updateWorkspace(data: $data, where: $where) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteWorkspace($where: WorkspaceUniqueUuidInput!) {\n  deleteWorkspace(where: $where) {\n    message\n  }\n}"): (typeof documents)["\n  mutation DeleteWorkspace($where: WorkspaceUniqueUuidInput!) {\n  deleteWorkspace(where: $where) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation InviteUserInWorkspace($data: InviteWorkspaceMembersInput!, $where: WorkspaceUniqueUuidInput!) {\n  inviteUserInWorkspace(data: $data, where: $where) {\n    message\n  }\n}"): (typeof documents)["\n  mutation InviteUserInWorkspace($data: InviteWorkspaceMembersInput!, $where: WorkspaceUniqueUuidInput!) {\n  inviteUserInWorkspace(data: $data, where: $where) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteMember($where: WorkspaceMemberUniqueInput!) {\n  deleteMember(where: $where) {\n    message\n  }\n}"): (typeof documents)["\n  mutation DeleteMember($where: WorkspaceMemberUniqueInput!) {\n  deleteMember(where: $where) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation UpdateMemberRole($where: WorkspaceMemberUniqueInput!, $data: UpdateMemberRoleInput!) {\n  updateMemberRole(where: $where, data: $data) {\n    message\n  }\n}"): (typeof documents)["\n mutation UpdateMemberRole($where: WorkspaceMemberUniqueInput!, $data: UpdateMemberRoleInput!) {\n  updateMemberRole(where: $where, data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation ResendInvitation($data: ResendInvitationInput!) {\n  resendInvitation(data: $data) {\n    message\n  }\n}"): (typeof documents)["\n mutation ResendInvitation($data: ResendInvitationInput!) {\n  resendInvitation(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation AcceptInvitation($data: AcceptInvitationInput!) {\n  acceptInvitation(data: $data) {\n    message\n  }\n}"): (typeof documents)["\n mutation AcceptInvitation($data: AcceptInvitationInput!) {\n  acceptInvitation(data: $data) {\n    message\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Workspaces($filter: ListWorkspaceFilter, $sort: [ListWorkspaceSort!]) {\n  workspaces(filter: $filter, sort: $sort) {\n    count\n    data {\n      id\n      name\n      uuid\n      workspaceMembers {\n        id\n        role\n      }\n    }\n  }}"): (typeof documents)["\n  query Workspaces($filter: ListWorkspaceFilter, $sort: [ListWorkspaceSort!]) {\n  workspaces(filter: $filter, sort: $sort) {\n    count\n    data {\n      id\n      name\n      uuid\n      workspaceMembers {\n        id\n        role\n      }\n    }\n  }}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Workspace($where: WorkspaceUniqueUuidInput!) {\n    workspace(where: $where) {\n      data {\n        name\n        uuid\n        default\n        workspaceMembers {\n          role\n        }\n      }\n    }\n}"): (typeof documents)["\n  query Workspace($where: WorkspaceUniqueUuidInput!) {\n    workspace(where: $where) {\n      data {\n        name\n        uuid\n        default\n        workspaceMembers {\n          role\n        }\n      }\n    }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query WorkspaceMembers($filter: ListWorkspaceMembersFilter, $sort: [ListWorkspaceMembersSort!], $where: WorkspaceUniqueUuidInput!) {\n    workspaceMembers(filter: $filter, sort: $sort, where: $where) {\n      count\n      totalMembers\n      subscriptionPlan {\n        features\n      }\n      data {\n        member {\n          email\n          name\n        }\n        role\n        status\n        email\n        id\n      }\n    }\n}"): (typeof documents)["\n  query WorkspaceMembers($filter: ListWorkspaceMembersFilter, $sort: [ListWorkspaceMembersSort!], $where: WorkspaceUniqueUuidInput!) {\n    workspaceMembers(filter: $filter, sort: $sort, where: $where) {\n      count\n      totalMembers\n      subscriptionPlan {\n        features\n      }\n      data {\n        member {\n          email\n          name\n        }\n        role\n        status\n        email\n        id\n      }\n    }\n}"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;