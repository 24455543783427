import Title from 'antd/es/typography/Title';
import { Palette } from '../form.types';

export default function FormHeader({
  title,
  activeTheme,
}: {
  title: string;
  activeTheme: Palette;
}) {
  return (
    <div
      className="form-header"
      style={{ backgroundColor: `${activeTheme?.textColor}1a` }}
    >
      <Title className="mb-0 text-header normal">{title}</Title>
    </div>
  );
}
