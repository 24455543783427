import { ReactElement, useContext } from 'react';
import { AppContext } from '../../AppContext';
import PermissionPageAccess from '../../components/common/PermissionPageAccess';
import { AppContextType } from '../../types/appContext.type';
import { Permissions } from '../../types/common.type';

type ProtectedComponentType = {
  children: ReactElement;
  requiredPermission: Permissions;
  showPage?: boolean;
};
export const ProtectedComponent: React.FC<ProtectedComponentType> = ({
  children,
  requiredPermission,
  showPage = false,
}) => {
  const { hasPermission } = useContext(AppContext) as AppContextType;

  if (!hasPermission(requiredPermission)) {
    if (showPage) {
      return <PermissionPageAccess />;
    }
    return null;
  }

  return children;
};
