('use Client');
import { Modal } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import subscribeBackground from '../../assets/images/Small-Popup.png';

type SuccessModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

const UpgradeSuccessful: React.FC<SuccessModalProps> = ({
  isVisible,
  onClose,
}) => {
  return (
    <>
      <Modal
        open={isVisible}
        width={600}
        onCancel={onClose}
        footer={null}
        className="subscribe-modal upgrade-Successful"
      >
        <img src={subscribeBackground} className="cover-img" alt="" />
        <div className="subscribe-modal-wrapper">
          <Title className="gradient-text">Upgrade Successful</Title>
          <Paragraph className="text-center text-base medium text-content-inverse-secondary">
            <b>CONGRATS!</b>
            <br /> You have successfully upgraded to premium plan for FREE.
            Please note premium features will only be available until we are in
            beta.
          </Paragraph>
        </div>
      </Modal>
    </>
  );
};

export default UpgradeSuccessful;
