import { Empty, Flex, Modal } from 'antd';

import {
  AddressBook,
  Calendar,
  CalendarBlank,
  CheckSquare,
  Clock,
  CurrencyDollar,
  EnvelopeSimpleOpen,
  Handshake,
  Image,
  LineSegment,
  NumberSquareNine,
  Phone,
  RadioButton,
  Star,
  UploadSimple,
  UserList,
} from '@phosphor-icons/react';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { startCase, toLower } from 'lodash';
import { useState } from 'react';
import { LargeTextIcon, ShortTextIcon } from '../../assets/icons';
import { FormFieldType, FormSchemaType } from '../../modules/form/form.types';
import CommonButton from '../primitives/CommonButton';
import CommonCheckBox from '../primitives/CommonCheckBox';
import CommonTag from '../primitives/CommonTag';

export const CommonQuestionReviewModal = ({
  visible,
  onClose,
  onSubmit,
  options,
}: {
  visible: boolean;
  onClose: () => void;
  onSubmit: (options: FormSchemaType[]) => void;
  options: FormSchemaType[];
}) => {
  const [selectedOptions, setSelectedOptions] = useState<FormSchemaType[]>(
    options?.filter((item: FormSchemaType) => item.question_id),
  );

  const handleCheckboxChange = (option: FormSchemaType) => {
    const selectedOption = selectedOptions.find(
      (item: FormSchemaType) => item.question_id === option.question_id,
    );
    if (selectedOption) {
      setSelectedOptions(
        selectedOptions.filter(
          (item: FormSchemaType) => item.question_id !== option.question_id,
        ),
      );
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSubmit = () => {
    onSubmit(selectedOptions);
    onClose();
  };

  const ModalTitle = () => (
    <Title level={4} className="font-secondary text-content-primary">
      Review questions
    </Title>
  );

  const selectOptionMapping: { [key: string]: React.ReactNode } = {
    [FormFieldType.SHORT_TEXT]: <ShortTextIcon />,
    [FormFieldType.LONG_TEXT]: <LargeTextIcon />,
    [FormFieldType.SELECT]: (
      <RadioButton size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.MULTI_SELECT]: (
      <CheckSquare size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.EMAIL]: (
      <EnvelopeSimpleOpen
        size={16}
        weight="fill"
        color="var(--content-primary)"
      />
    ),
    [FormFieldType.NUMBER]: (
      <NumberSquareNine
        size={16}
        weight="fill"
        color="var(--content-primary)"
      />
    ),
    [FormFieldType.RATE]: (
      <Star size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.LINEAR_SCALE]: (
      <LineSegment size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.FILE]: (
      <UploadSimple size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.DATE]: (
      <CalendarBlank size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.TIME]: (
      <Clock size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.PHONE_NUMBER]: (
      <Phone size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.CONTACT_INFO]: (
      <AddressBook size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.ADDRESS]: (
      <UserList size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.PAYMENT]: (
      <CurrencyDollar size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.CALENDAR]: (
      <Handshake size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.DATE_RANGE]: (
      <Calendar size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.PICTURE_CHOICE]: (
      <Image size={16} weight="fill" color="var(--content-primary)" />
    ),
  };

  const renderQuestionType = (type: FormFieldType) => {
    return (
      <CommonTag
        icon={selectOptionMapping?.[type]}
        color="default"
        bordered={false}
        className="medium"
      >
        {startCase(toLower(type))}
      </CommonTag>
    );
  };

  return (
    <Modal
      title={<ModalTitle />}
      open={visible}
      width={700}
      onCancel={onClose}
      destroyOnClose
      className="review-question-modal"
      footer={
        options?.length > 0
          ? [
              <CommonButton
                key="submit"
                type="primary"
                onClick={handleSubmit}
                disabled={selectedOptions?.length === 0}
              >
                Add questions
              </CommonButton>,
            ]
          : null
      }
    >
      <Paragraph className="text-sm text-content-secondary mb-16">
        Here are the generated question, choose which questions do you want to
        add.
      </Paragraph>
      <Flex gap={12} vertical>
        {options?.length > 0 ? (
          options.map((option: FormSchemaType) => (
            <CommonCheckBox
              key={option.question_id}
              boxType="primary"
              onChange={() => handleCheckboxChange(option)}
              checked={
                selectedOptions.find(
                  (item) => item.question_id === option.question_id,
                ) !== undefined
              }
            >
              <Flex
                gap={8}
                className="add-question-review-checkbox"
                vertical
                align="flex-start"
              >
                {renderQuestionType(option.field_type as FormFieldType)}
                <Paragraph className="text-base text-content-primary mb-0">
                  {option.question_text}
                </Paragraph>
              </Flex>
            </CommonCheckBox>
          ))
        ) : (
          <Empty description="No questions generated" />
        )}
      </Flex>
    </Modal>
  );
};
