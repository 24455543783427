import { Question, WarningOctagon } from '@phosphor-icons/react';
import { ArrowsClockwise } from '@phosphor-icons/react/dist/ssr';
import { Flex, Result } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import CommonButton from '../../../../components/primitives/CommonButton';
import { AIGenerateFormError } from '../../form.types';

type AIErrorFormType = {
  onRetry: () => void;
  onAddQuestion: () => void;
  onStartAgain: () => void;
  code?: string;
  errorMessage?: string;
};

export default function AIErrorForm({
  onRetry,
  onAddQuestion,
  onStartAgain,
  code,
  errorMessage,
}: AIErrorFormType) {
  const renderTitle = () => {
    switch (code) {
      case AIGenerateFormError.INVALID_PROMPT:
        return 'Looks like you entered an invalid prompt.';
      default:
        return 'Oops! There was some error in creating questions';
    }
  };
  const renderSubTitle = () => {
    switch (code) {
      case AIGenerateFormError.INVALID_PROMPT:
        return errorMessage;
      default:
        return 'No worries, you can still add questions manually or try again with the same prompt';
    }
  };
  return (
    <div className="scroll-wrapper flex justify-center items-center">
      <Result
        status="error"
        icon={<WarningOctagon color="var(--danger-on-surface)" size={56} />}
        title={
          <Title level={3} className="font-secondary medium">
            {renderTitle()}
          </Title>
        }
        subTitle={
          <Paragraph className="mb-0 text-content-secondary">
            {renderSubTitle()}
          </Paragraph>
        }
        extra={
          <Flex gap={8} wrap justify="center" align="center">
            <CommonButton
              key="add-question"
              type="text"
              onClick={() => {
                onAddQuestion();
              }}
              icon={
                <Question
                  size={16}
                  color="var(--content-primary)"
                  weight="fill"
                />
              }
            >
              Add question
            </CommonButton>
            {code === AIGenerateFormError.INVALID_PROMPT ? (
              <CommonButton
                type="primary"
                key="retry"
                icon={
                  <ArrowsClockwise
                    size={16}
                    color="var(--content-inverse-primary)"
                    weight="fill"
                  />
                }
                onClick={() => {
                  onStartAgain();
                }}
              >
                Start again
              </CommonButton>
            ) : (
              <CommonButton
                type="primary"
                key="retry"
                icon={
                  <ArrowsClockwise
                    size={16}
                    color="var(--content-inverse-primary)"
                    weight="fill"
                  />
                }
                onClick={() => {
                  onRetry();
                }}
              >
                Retry
              </CommonButton>
            )}
          </Flex>
        }
      ></Result>
    </div>
  );
}
