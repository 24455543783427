import { ConfigProvider } from 'antd';
import { ROUTES } from '../../../../common/constants';
import useRouter from '../../../../hooks/useRouter';
import EditShare from './EditShare';
import ShareList from './ShareList';

export const FormSharing = () => {
  const { params, navigate } = useRouter();
  const formId = params?.id as string;
  const shareId = params?.shareId as string;

  return (
    <section>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: 'var(--surface-secondary)',
            colorPrimary: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
            },
            Input: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
            },
            Select: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              optionActiveBg: 'var(--surface-tertiary)',
              optionSelectedBg: 'var(--surface-tertiary)',
              colorBorder: 'var(--border-primary)',
            },
            DatePicker: {
              borderRadius: 16,
              colorBorder: 'var(--border-primary)',
            },
          },
        }}
      >
        <section className="zinq-form-builder-config">
          <div className="flex justify-center mx-auto flex-column">
            <div className="background-wrapper h-screen">
              {shareId ? (
                <EditShare
                  onBack={() => {
                    navigate(`${ROUTES.SHARE}/${formId}`);
                    // setCounter(1);
                  }}
                />
              ) : (
                <ShareList
                  onBack={() => {
                    // setCounter(2);
                    navigate(`${ROUTES.SHARE}/${formId}/${shareId}`);
                  }}
                  onClick={(id: string) => {
                    navigate(`${ROUTES.SHARE}/${formId}/${id}`);
                  }}
                />
              )}
            </div>
          </div>
        </section>
      </ConfigProvider>
    </section>
  );
};
