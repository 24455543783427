import { useMutation } from '@apollo/client';
import { Divider, Flex, Spin, Statistic } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Link from 'antd/es/typography/Link';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { Dayjs } from 'dayjs';
import {
  GoogleAuthProvider,
  OAuthProvider,
  RecaptchaVerifier,
  User as UserAuth,
  UserCredential,
  signInWithPopup,
} from 'firebase/auth';
import { omit } from 'lodash';
import { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { FormThemeMode, LoginType, User } from '../../__generated__/graphql';
import { GoogleIcon, MicrosoftIcon } from '../../assets/icons';
import { EVENT_DATA, EVENT_NAME, ROUTES } from '../../common/constants';
import {
  deleteCookie,
  getDefaultTimezone,
  handleFirebaseError,
  triggerEvent,
} from '../../common/utils';
import Logo from '../../components/common/Logo';
import { messageContext } from '../../components/context/AppContextHolder';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { ErrorTypes, InputDataType, TokenType } from './auth.types';
import { auth, googleProvider, microsoftProvider } from './firebase/config';
import { FIREBASE_LOGIN, UPDATE_USER_PROFILE } from './graphql/mutations';

const { Countdown } = Statistic;
const OTP_MODE = process.env.REACT_APP_OTP_MODE === 'true' ? true : false;

const Register = () => {
  const [step, setStep] = useState(1);
  const [contactForm] = useForm();
  const [metaForm] = useForm();
  const [otpForm] = useForm();
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState<Dayjs | null>();
  const [inputData, setInputData] = useState<InputDataType>();
  const { location } = useRouter();
  const [reCaptcha, setReCaptcha] = useState<RecaptchaVerifier>();
  const [errors, setErrors] = useState<ErrorTypes>({});
  const { navigate } = useRouter();

  const { initializeAuth, dispatch } = useContext(AppContext) as AppContextType;

  const [firebaseLoginMutate, { loading: loginLoading }] = useMutation(
    FIREBASE_LOGIN,
    {
      onError() {},
    },
  );

  const [updateUserProfileMutate, { loading: metaLoading }] = useMutation(
    UPDATE_USER_PROFILE,
    {
      onError() {},
    },
  );

  const [loginType, setLoginType] = useState<LoginType>();

  const redirectUser = (
    user: User,
    accessToken: string,
    refreshToken: string,
    route: string,
    isNewUser: boolean,
  ) => {
    initializeAuth(
      accessToken,
      user,
      refreshToken,
      isNewUser ? ROUTES.ONBOARDING : route,
    );
  };

  const handleRegister = (
    provider: GoogleAuthProvider | OAuthProvider,
    loginType: LoginType,
  ) => {
    setLoading(true);
    setLoginType(loginType);
    signInWithPopup(auth, provider)
      .then((result: UserCredential) => {
        const credential = OAuthProvider.credentialFromResult(result);

        if (!OTP_MODE) {
          const user: UserAuth & TokenType = result.user;
          firebaseLoginMutate({
            variables: {
              data: {
                name: user.displayName || '',
                token: user.accessToken || '',
                logInType: loginType,
                timezone: getDefaultTimezone(),
              },
            },
            onCompleted: async (res) => {
              const data = res.firebaseLogin;
              if (data) {
                // localStorage.setItem(TOKEN, data?.accessToken as string);
                // setInputData({
                //   ...inputData,
                //   user: data?.user as User,
                //   accessToken: data?.accessToken as string,
                //   refreshToken: data?.refreshToken as string,
                // });
                // setStep(4);
                // dispatch({
                //   type: AppActionType.setRedirectRoute,
                //   data: ROUTES.FORM_CREATE,
                // });
                redirectUser(
                  omit(data?.user, ['__typename']),
                  data.accessToken as string,
                  data.refreshToken as string,
                  ROUTES.MAIN,
                  data.user?.isNewUser || false,
                );
              }
            },
          });
        } else {
          // check if phone number is linked or not.
          if (!result.user.phoneNumber) {
            if (credential?.accessToken) {
              contactForm.setFieldValue('name', result.user.displayName);
              // handleAccessToken(credential.accessToken);
              setStep(2);
            }
          } else {
            const user: UserAuth & TokenType = result.user;
            firebaseLoginMutate({
              variables: {
                data: {
                  name: user.displayName || '',
                  token: user.accessToken || '',
                  timezone: getDefaultTimezone(),
                  logInType: loginType,
                },
              },
              onCompleted: async (res) => {
                const data = res.firebaseLogin;
                if (data) {
                  dispatch({
                    type: AppActionType.setRedirectRoute,
                    data: ROUTES.FORM_CREATE,
                  });
                  redirectUser(
                    omit(data?.user, ['__typename']),
                    data.accessToken as string,
                    data.refreshToken as string,
                    ROUTES.FORM_CREATE,
                    data.user?.isNewUser || false,
                  );
                }
              },
            });
          }
        }
      })
      .catch((error) => {
        messageContext.error(handleFirebaseError(error));
      })
      .finally(() => {
        setLoading(false);
      });

    triggerEvent(
      EVENT_NAME.SIGNUP_BUTTON_CLICK,
      loginType === LoginType.Google
        ? EVENT_DATA.GOOGLE_BUTTON_CLICK
        : EVENT_DATA.MICROSOFT_BUTTON_CLICK,
    );
  };

  const handleSignInLink = () => {
    deleteCookie('email');
    deleteCookie('timer');
    navigate(ROUTES.LOGIN);
  };

  const handleSignUpWithEmail = () => {
    triggerEvent(EVENT_NAME.SIGNUP_BUTTON_CLICK, EVENT_DATA.EMAIL_BUTTON_CLICK);
    navigate(ROUTES.REGISTER_EMAIL);
  };

  return (
    <section className="auth-wrapper">
      <div className="auth-form">
        <div className="flex justify-center">
          <Logo mode={FormThemeMode.Light} link={ROUTES.LOGIN} />
        </div>
        <Flex gap={4} vertical>
          <Title
            level={2}
            className="text-center semi-bold font-secondary text-content-primary"
          >
            Tired of chasing data? Let's automate it.
          </Title>
          <Paragraph className="mb-0 text-center text-content-tertiary">
            Sign in to your ZINQ account or create a new one to start collecting
            effortlessly.
          </Paragraph>
        </Flex>
        <Spin spinning={loading || loginLoading}>
          <Flex className="social-groups" gap={24} vertical>
            <Flex vertical gap={24}>
              <Flex vertical gap={16}>
                <CommonButton
                  type="text"
                  icon={<GoogleIcon />}
                  onClick={() =>
                    handleRegister(googleProvider, LoginType.Google)
                  }
                >
                  Continue with Google
                </CommonButton>
                <CommonButton
                  type="text"
                  icon={<MicrosoftIcon />}
                  onClick={() =>
                    handleRegister(microsoftProvider, LoginType.Microsoft)
                  }
                >
                  Continue with Microsoft
                </CommonButton>
              </Flex>
              <Divider orientation="center" orientationMargin={24}>
                <Paragraph className="mb-0 text-content-secondary text-meta">
                  OR
                </Paragraph>
              </Divider>
              <CommonButton type="primary" onClick={handleSignUpWithEmail}>
                Sign up with Email
              </CommonButton>
            </Flex>
          </Flex>
        </Spin>
        <div>
          <Paragraph className="mb-0 text-center text-content-tertiary">
            Already have an account?{' '}
            <Link onClick={handleSignInLink} underline>
              Sign In
            </Link>
          </Paragraph>
        </div>
      </div>
    </section>
  );
};

export default Register;
