import { Result } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { EmptyFolderSvg } from '../../assets/icons';

const EmptyResponse = () => (
  <div className="empty-data d-flex justify-center align-center">
    <Result
      icon={<EmptyFolderSvg />}
      title={
        <Paragraph className="mb-0 text-m text-content-secondary medium">
          No responses yet
        </Paragraph>
      }
    />
  </div>
);
export default EmptyResponse;
