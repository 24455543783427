import { CrownSimple } from '@phosphor-icons/react';
import { Tooltip } from 'antd';
import { COMING_SOON_TEXT } from '../../common/constants';
import useFeatureAccess from '../../hooks/useFeatureAccess';
import { FeatureKeys } from '../../modules/profile/profile.types';
import SubscribeModal from './SubscribeModal';
import UpgradeSuccessful from './UpgradeSuccessful';

export default function PremiumLogo({
  isTooltip = false,
  isSubscriptionModal = false,
  featureKey,
}: {
  isTooltip?: boolean;
  isSubscriptionModal?: boolean;
  featureKey?: FeatureKeys;
}) {
  const {
    checkFeatureAccess,
    isSubscriptionModalVisible,
    isUpgradeSuccessfulModalVisible,
    handleUpgradeSuccess,
    closeSubscriptionModal,
    closeUpgradeSuccessfulModal,
  } = useFeatureAccess();

  const handleClick = () => {
    if (featureKey) {
      checkFeatureAccess(featureKey, () => {
        // do nothing
      });
    }
  };
  return (
    <>
      <Tooltip title={isTooltip ? COMING_SOON_TEXT : ''}>
        <CrownSimple
          className="premium-logo cursor-pointer flex flex-shrink-0"
          color="var(--premium-yellow)"
          size={14}
          weight="fill"
          onClick={isSubscriptionModal ? handleClick : undefined}
        />
        {isSubscriptionModalVisible && (
          <SubscribeModal
            visible={isSubscriptionModalVisible}
            onCancel={closeSubscriptionModal}
            onUpgrade={handleUpgradeSuccess}
          />
        )}
        {isUpgradeSuccessfulModalVisible && (
          <UpgradeSuccessful
            isVisible={isUpgradeSuccessfulModalVisible}
            onClose={closeUpgradeSuccessfulModal}
          />
        )}
      </Tooltip>
    </>
  );
}
