import { Swatches } from '@phosphor-icons/react';
import { ConfigProvider, Flex, Form, Segmented } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { useContext } from 'react';
import {
  FormDesign as FormDesignType,
  FormThemeMode,
} from '../../../../__generated__/graphql';
import { AppContext } from '../../../../AppContext';
import {
  DarkModeFormIcon,
  LightModeFormIcon,
  SystemModeFormIcon,
} from '../../../../assets/icons';
import { hasFeatureAccess } from '../../../../common/utils';
import PremiumLogo from '../../../../components/common/PremiumLogo';
import CommonButton from '../../../../components/primitives/CommonButton';
import CommonSwitch from '../../../../components/primitives/CommonSwitch';
import {
  AppActionType,
  AppContextType,
} from '../../../../types/appContext.type';
import { Permissions } from '../../../../types/common.type';
import { FeatureKeys } from '../../../profile/profile.types';
import ThemeList from '../utils/ThemeList';
import ThemeVariationsList from '../utils/ThemeVariationsList';
import { CommonPageTypes } from './pages.types';

export const FormDesign: React.FC<
  CommonPageTypes & {
    formDesign: FormDesignType;
    formId: string;
    onChange: (obj: FormDesignType) => void;
  }
> = ({ onChange, formDesign, formId }) => {
  const {
    state: { themeList, currentUser },
    dispatch,
    hasPermission,
  } = useContext(AppContext) as AppContextType;
  const editAccess = hasPermission(Permissions.WRITE);

  const allowRemoveBranding = hasFeatureAccess(
    currentUser?.subscriptionPlan?.features,
    FeatureKeys.REMOVE_BRANDING,
  );
  const menuItems = [
    {
      label: 'Light',
      value: FormThemeMode.Light,
      icon: <LightModeFormIcon />,
    },
    {
      label: 'Dark',
      value: FormThemeMode.Dark,
      icon: <DarkModeFormIcon />,
    },
    {
      label: 'Auto',
      value: FormThemeMode.Auto,
      icon: <SystemModeFormIcon />,
    },
  ];

  return (
    <section>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: 'var(--surface-secondary)',
            colorPrimary: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
            },
            Input: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
            },
          },
        }}
      >
        <Form disabled={!editAccess}>
          <Flex gap={32} wrap vertical className="scroll-wrapper-body">
            <Flex gap={18} vertical>
              <Paragraph className="mb-0 text-content-primary text-m semi-bold">
                Choose Mode
              </Paragraph>
              <Segmented
                value={formDesign?.themeMode as FormThemeMode}
                className="vertical-segments"
                onChange={(value) => {
                  onChange({
                    themeMode: value,
                  });
                  dispatch({
                    type: AppActionType.setActiveThemeIds,
                    data: {
                      activeMode: value,
                    },
                  });
                }}
                options={menuItems}
                disabled={!editAccess}
              />
            </Flex>
            <Flex gap={16} vertical>
              <Flex gap={8} justify="space-between" align="center">
                <Paragraph className="mb-0 text-content-primary text-m semi-bold">
                  Choose Theme
                </Paragraph>
                <CommonButton
                  icon={
                    <Swatches
                      size={14}
                      color="text-content-tertiary"
                      weight="fill"
                    />
                  }
                  type="text"
                  premium
                  size="small"
                  isTooltip
                  disabled={false}
                >
                  Create theme
                </CommonButton>
              </Flex>

              {themeList?.length > 0 && (
                <ThemeList
                  themeList={themeList}
                  formThemeMode={formDesign?.themeMode as FormThemeMode}
                  value={formDesign?.themeId as string}
                  onChange={(value: FormDesignType) => {
                    onChange(value);
                  }}
                  disabled={!editAccess}
                />
              )}
            </Flex>
            <Flex gap={16} vertical>
              <Flex gap={8} justify="space-between" align="center">
                <Paragraph className="mb-0 text-content-primary text-m semi-bold">
                  Choose Color
                </Paragraph>
                <CommonButton
                  icon={
                    <Swatches
                      size={14}
                      color="text-content-tertiary"
                      weight="fill"
                    />
                  }
                  isTooltip
                  type="text"
                  premium
                  size="small"
                  disabled={false}
                >
                  Customize
                </CommonButton>
              </Flex>
              {themeList?.length > 0 && (
                <ThemeVariationsList
                  themeList={themeList}
                  value={formDesign?.themeVariationId as string}
                  themeId={formDesign?.themeId as string}
                  onChange={(value: FormDesignType) => onChange(value)}
                  formThemeMode={formDesign?.themeMode as FormThemeMode}
                  disabled={!editAccess}
                />
              )}
            </Flex>
            <Flex gap={16} justify="space-between" align="flex-start">
              <Flex vertical>
                <Flex align="center" gap={8}>
                  <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                    Remove Branding
                  </Paragraph>
                  {!allowRemoveBranding && (
                    <PremiumLogo
                      isSubscriptionModal
                      featureKey={FeatureKeys.REMOVE_BRANDING}
                    />
                  )}
                </Flex>
                <Flex>
                  <Paragraph className="mb-0 text-content-tertiary medium">
                    Remove ZINQ’s branding.
                  </Paragraph>
                </Flex>
              </Flex>
              <CommonSwitch
                type="primary"
                disabled={!allowRemoveBranding || !editAccess}
                checked={!!formDesign.removeBranding}
                onChange={(checked) => {
                  onChange({
                    removeBranding: checked,
                  });
                  dispatch({
                    type: AppActionType.setActiveThemeIds,
                    data: {
                      removeBranding: checked,
                    },
                  });
                }}
              />
            </Flex>
            <Flex gap={16} justify="space-between" align="flex-start">
              <Flex vertical>
                <Flex align="center" gap={8}>
                  <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                    Brand Logo
                  </Paragraph>
                  <PremiumLogo isTooltip />
                </Flex>
                <Paragraph className="mb-0 text-content-tertiary medium">
                  Display your logo on the welcome and end page.
                </Paragraph>
              </Flex>
              <CommonSwitch type="primary" disabled isTooltip />
            </Flex>
            {/* <Flex>
            <CommonButton type="text" icon={<UploadSimple />}>
              Upload
            </CommonButton>
          </Flex> */}
          </Flex>
        </Form>
      </ConfigProvider>
    </section>
  );
};
