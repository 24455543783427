import { useMutation } from '@apollo/client';
import { CheckCircle } from '@phosphor-icons/react';
import { Flex, Form, Input, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormThemeMode, SignUpInputData } from '../../__generated__/graphql';
import { ROUTES } from '../../common/constants';
import { formValidation, handleGraphQlError } from '../../common/utils';
import Logo from '../../components/common/Logo';
import CommonButton from '../../components/primitives/CommonButton';
import useQueryParams from '../../hooks/useQueryParams';
import useRouter from '../../hooks/useRouter';
import { RESET_PASSWORD } from './graphql/mutations';

const ResetPassword = () => {
  const { navigate } = useRouter();
  const [form] = useForm();
  const [step, setStep] = useState(1);
  const { getQueryParam } = useQueryParams();
  const token = getQueryParam('token') as string;
  const uid = getQueryParam('uid') as string;

  const [resetPasswordMutate, { loading }] = useMutation(RESET_PASSWORD, {
    onError: () => {},
  });

  const handleResetPassword = (payload: SignUpInputData) => {
    const { password } = payload;
    resetPasswordMutate({
      variables: {
        data: { password, token, uid },
      },
      onCompleted: () => {
        setStep(2);
      },
      onError: (err) => {
        handleGraphQlError(err.message);
        navigate(ROUTES.LOGIN_EMAIL, {
          replace: true,
        });
      },
    });
  };
  useEffect(() => {
    if (!uid && !token) {
      navigate(ROUTES.LOGIN);
    }
  }, []);

  return (
    <section className="auth-wrapper">
      <div className="auth-form">
        <div className="flex justify-center">
          <Logo mode={FormThemeMode.Light} link={ROUTES.LOGIN} />
        </div>
        {step === 1 && (
          <>
            <Spin spinning={loading}>
              <div className="social-groups">
                <Form
                  form={form}
                  name="register"
                  onFinish={handleResetPassword}
                  layout="vertical"
                  scrollToFirstError
                  autoComplete="off"
                >
                  <Form.Item
                    name="password"
                    label="Password"
                    hasFeedback
                    rules={[formValidation.required, formValidation.password]}
                  >
                    <Input.Password
                      placeholder="Enter password"
                      maxLength={255}
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      formValidation.required,
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('Passwords do not match!'),
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Re-enter password"
                      maxLength={255}
                    />
                  </Form.Item>

                  <Form.Item>
                    <CommonButton type="primary" htmlType="submit" block>
                      Continue
                    </CommonButton>
                  </Form.Item>
                </Form>
              </div>
            </Spin>
          </>
        )}
        {step === 2 && (
          <Flex vertical gap={24}>
            <Flex vertical gap={8} justify="center" align="center">
              <CheckCircle
                color="var(--success-on-surface)"
                size={64}
                weight="fill"
              />
              <Title className="mb-0 semi-bold">
                Password Reset Successfully
              </Title>
            </Flex>
            <Link to={ROUTES.LOGIN_EMAIL} replace>
              <CommonButton type="primary" block>
                Sign In
              </CommonButton>
            </Link>
          </Flex>
        )}
      </div>
    </section>
  );
};

export default ResetPassword;
