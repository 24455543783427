import { Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import { lowerCase } from 'lodash';
import { FormSubmissionType } from '../../../../__generated__/graphql';
import ClassicGif from '../../../../assets/gifs/classic-switching.gif';
import DefaultGif from '../../../../assets/gifs/default-switching.gif';
export default function SwitchingMode({ type }: { type: FormSubmissionType }) {
  const typeLabel =
    type === FormSubmissionType.Conversation ? 'default' : lowerCase(type);

  const gifMapping = {
    [FormSubmissionType.Conversation]: DefaultGif,
    [FormSubmissionType.Classic]: ClassicGif,
    [FormSubmissionType.Chat]: ClassicGif, // to be changed when updated
    [FormSubmissionType.Voice]: ClassicGif, // to be changed when updated
  };

  return (
    <Flex align="center" vertical gap={8}>
      <img
        src={gifMapping?.[type]}
        alt="form switching"
        height={100}
        width={100}
      />
      <Title level={2} className="text-content-secondary text-center">
        {`Switching to ${typeLabel} mode`}
      </Title>
    </Flex>
  );
}
