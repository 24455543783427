import { useMutation } from '@apollo/client';
import { omit } from 'lodash';
import { useContext, useEffect } from 'react';
import { User } from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import ScreenLayout from '../../components/layouts/ScreenLayout';
import useQueryParams from '../../hooks/useQueryParams';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import FormLoading from '../form/editor/utils/FormLoading';
import { VERIFY_EMAIL } from './graphql/mutations';

export default function VerifyEmail() {
  const { initializeAuth } = useContext(AppContext) as AppContextType;
  const { getQueryParam } = useQueryParams();
  const { location, navigate } = useRouter();
  const token = getQueryParam('token') as string;
  const uid = getQueryParam('uid') as string;
  const [verifyEmailMutate, { loading }] = useMutation(VERIFY_EMAIL, {
    onError: () => {},
  });

  const redirectUser = (
    user: User,
    accessToken: string,
    refreshToken: string,
    route: string,
    isNewUser: boolean,
  ) => {
    initializeAuth(
      accessToken,
      user,
      refreshToken,
      isNewUser ? ROUTES.ONBOARDING : route,
    );
  };

  useEffect(() => {
    if (uid && token) {
      verifyEmailMutate({
        variables: {
          data: { uid, token },
        },
        onCompleted: (res) => {
          const data = res.verifyEmail;
          redirectUser(
            omit(data?.user, ['__typename']),
            data?.accessToken || '',
            data?.refreshToken || '',
            ROUTES.ONBOARDING,
            true,
          );
        },
        onError: () => {
          navigate(ROUTES.LOGIN, { replace: true });
        },
      });
    } else {
      navigate(ROUTES.LOGIN, { replace: true });
    }
  }, []);

  if (loading) {
    return (
      <ScreenLayout>
        <FormLoading />
      </ScreenLayout>
    );
  }
  return null;
}
