import { useMutation } from '@apollo/client';
import { Flex, Form, Input, Spin, Statistic } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Link from 'antd/es/typography/Link';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  ForgotPasswordInput,
  FormThemeMode,
} from '../../__generated__/graphql';
import { COMPANY_INFO, ROUTES } from '../../common/constants';
import {
  deleteCookie,
  formValidation,
  getCookie,
  setCookie,
} from '../../common/utils';
import Logo from '../../components/common/Logo';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import {
  FORGOT_PASSWORD,
  RESEND_VERIFICATION_EMAIL,
} from './graphql/mutations';

const { Countdown } = Statistic;

const ForgotPassword = () => {
  const { location, navigate } = useRouter();
  const [form] = useForm();
  const timerCookie = getCookie('timer') || null;
  const emailCookie = getCookie('email') || null;
  const [timerValue, setTimerValue] = useState(timerCookie);
  const [step, setStep] = useState(emailCookie ? 2 : 1);

  const { initializeAuth, dispatch } = useContext(AppContext) as AppContextType;

  const [forgotPasswordMutate, { loading }] = useMutation(FORGOT_PASSWORD, {
    onError: () => {},
  });

  const [resendEmailMutate, { loading: resendLoading }] = useMutation(
    RESEND_VERIFICATION_EMAIL,
    {
      onError: () => {},
    },
  );

  const renderTitle = useMemo(() => {
    switch (step) {
      case 1:
        return 'Forgot Password';
      case 2:
        return 'Email sent';
      default:
        return '';
    }
  }, [step]);

  const manageTimer = (email: string) => {
    const timerStamp = dayjs()
      ?.add(COMPANY_INFO?.resendEmailTimer, 'minutes')
      ?.format();
    setCookie('timer', timerStamp, COMPANY_INFO?.resendEmailTimer);
    setCookie('email', email, null);
    setTimerValue(timerStamp);
    setStep(2);
  };

  const handleResendEmail = () => {
    const email = form.getFieldValue('email') || getCookie('email');
    resendEmailMutate({
      variables: {
        data: { email },
      },
      onCompleted: () => {
        manageTimer(email);
      },
    });
  };

  const handleForgotPassword = (payload: ForgotPasswordInput) => {
    const { email } = payload;
    forgotPasswordMutate({
      variables: {
        data: { email },
      },
      onCompleted: () => {
        manageTimer(email);
      },
      onError: (err) => {
        form.setFields([
          {
            name: 'email',
            errors: [err?.message],
          },
        ]);
      },
    });
  };

  const handleTimerFinish = () => {
    setTimerValue(null);
    setStep(1);
  };

  const handleNotEmail = () => {
    deleteCookie('email');
    deleteCookie('timer');
    setTimerValue(null);
    setStep(1);
  };

  const renderDescription = useMemo(() => {
    const sentEmail = form.getFieldValue('email') || getCookie('email');
    switch (step) {
      case 1:
        return 'Enter your registered email';
      case 2:
        return `We have sent an email to ${sentEmail} for verification.`;
      default:
        return '';
    }
  }, [step]);

  return (
    <section className="auth-wrapper">
      <div className="auth-form">
        <div className="flex justify-center">
          <Logo mode={FormThemeMode.Light} link={ROUTES.LOGIN} />
        </div>
        <Flex gap={4} vertical>
          <Title
            level={2}
            className="text-center semi-bold font-secondary text-content-primary"
          >
            {renderTitle}
          </Title>
          <Paragraph className="mb-0 text-center text-content-tertiary">
            {renderDescription}
          </Paragraph>

          {step === 2 && (
            <p className="text-neutrals-700 text-base text-center">
              <Link underline onClick={handleNotEmail}>
                Not your email?
              </Link>
            </p>
          )}
        </Flex>
        {step === 1 && (
          <>
            <Spin spinning={loading}>
              <div className="social-groups">
                <Form
                  form={form}
                  name="register"
                  onFinish={handleForgotPassword}
                  layout="vertical"
                  scrollToFirstError
                  autoComplete="off"
                >
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[formValidation.required, formValidation.email]}
                  >
                    <Input placeholder="Enter your email" />
                  </Form.Item>

                  <Form.Item>
                    <CommonButton type="primary" htmlType="submit" block>
                      Continue
                    </CommonButton>
                  </Form.Item>
                </Form>
              </div>
            </Spin>
          </>
        )}
        {step === 2 &&
          (timerValue ? (
            <CommonButton disabled>
              Resend in
              <Statistic.Countdown
                format="mm:ss"
                value={timerValue}
                onFinish={handleTimerFinish}
              />
            </CommonButton>
          ) : (
            <CommonButton onClick={handleResendEmail} loading={resendLoading}>
              Resend
            </CommonButton>
          ))}
      </div>
    </section>
  );
};

export default ForgotPassword;
