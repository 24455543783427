import { ConfigProvider, Flex, Skeleton } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { SparkleAISvg } from '../../../../assets/icons';
import { Palette } from '../../form.types';

type AIProcessingFormType = {
  type?: 'EDITOR' | 'PREVIEW';
  activeTheme?: Palette;
};
export default function AIProcessingForm({
  type = 'EDITOR',
  activeTheme,
}: AIProcessingFormType) {
  if (type === 'EDITOR') {
    return (
      <div className="scroll-wrapper flex justify-center items-center">
        <Flex vertical gap={12} align="center">
          <SparkleAISvg />
          <Flex vertical gap={8} align="center">
            <Title
              level={2}
              className="mb-0 text-content-primary semi-bold text-center font-secondary"
            >
              Generating...
            </Title>
            <Paragraph className="mb-0 text-content-secondary text-base medium text-center">
              Hang tight! It will be worth it.
            </Paragraph>
          </Flex>
        </Flex>
      </div>
    );
  }
  return (
    <ConfigProvider
      theme={{
        components: {
          Skeleton: {
            colorFillContent: activeTheme?.textColor,
          },
        },
      }}
    >
      <Skeleton active />
    </ConfigProvider>
  );
}
