import { useMutation } from '@apollo/client';
import { Form, Input, Modal } from 'antd';
import Title from 'antd/es/typography/Title';
import { trim } from 'lodash';
import React, { useState } from 'react';
import { Workspace } from '../../__generated__/graphql';
import {
  FORM_CHARACTER_LIMIT,
  graphQLErrorsCode,
  MODAL_SIZE,
  ROUTES,
} from '../../common/constants';
import { formValidation, getDefaultTimezone } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { CREATE_USER_WORKSPACE } from '../../modules/workspace/graphql/mutations';
import CommonButton from '../primitives/CommonButton';
import CommonWorkspaceAlertModal from './CommonWorkspaceAlertModal';

type CommonWorkspaceCreateModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: (value: Workspace) => void;
  title: string;
};

const CommonWorkspaceCreateModal: React.FC<CommonWorkspaceCreateModalProps> = ({
  isVisible,
  onCancel,
  onConfirm,
  title,
}) => {
  const [form] = Form.useForm();
  const [alertModal, setAlertModal] = useState(false);
  const { navigate } = useRouter();

  const [createWorkspaceMutate, { loading }] = useMutation(
    CREATE_USER_WORKSPACE,
    {
      onError: () => {},
    },
  );

  const handleConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        createWorkspaceMutate({
          variables: {
            data: {
              name: trim(values?.name),
              timezone: getDefaultTimezone(),
            },
          },
          onCompleted: (res) => {
            onConfirm(res.createWorkspace?.data || {});
          },
          onError: (error) => {
            if (
              error.graphQLErrors?.[0]?.extensions?.code ===
              graphQLErrorsCode?.FREE_WORKSPACE_ALREADY_EXISTS
            ) {
              setAlertModal(true);
            } else {
              form.setFields([
                {
                  name: 'name',
                  errors: [error?.message],
                },
              ]);
            }
          },
        });
      })
      .catch(() => {
        // do nothing
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const ModalTitle = () => (
    <Title level={4} className="font-secondary text-content-primary">
      {title}
    </Title>
  );

  return (
    <>
      <Modal
        title={<ModalTitle />}
        open={isVisible}
        onCancel={handleCancel}
        className="common-key-modal"
        width={MODAL_SIZE.large}
        footer={[
          <CommonButton key="cancel" onClick={handleCancel} disabled={loading}>
            Cancel
          </CommonButton>,
          <CommonButton
            key="confirm"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={() => {
              form.submit();
            }}
          >
            Create
          </CommonButton>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          className="w-full"
          onFinish={handleConfirm}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label="Workspace Name"
            rules={[formValidation.required, formValidation.whitespace]}
          >
            <Input
              placeholder="Type here..."
              className="w-full"
              maxLength={FORM_CHARACTER_LIMIT.workspaceNameLimit}
            />
          </Form.Item>
        </Form>
      </Modal>
      {alertModal && (
        <CommonWorkspaceAlertModal
          isVisible={alertModal}
          onCancel={() => {
            setAlertModal(false);
          }}
          onConfirm={(uuid) => {
            setAlertModal(false);
            handleCancel();
            navigate(uuid ? `${ROUTES.PLAN_BILLING}/${uuid}` : ROUTES.ACCOUNT);
          }}
        />
      )}
    </>
  );
};

export default CommonWorkspaceCreateModal;
