import { useMutation } from '@apollo/client';
import { CaretLeft, Plus, Sparkle } from '@phosphor-icons/react';
// import { ConfigProvider, Divider, Flex, Form, Input } from 'antd';
import { ConfigProvider, Divider, Flex, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Paragraph from 'antd/es/typography/Paragraph';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { TOAST_MESSAGE } from '../../../../common/constants';
import { formValidation } from '../../../../common/utils';
import { CommonQuestionReviewModal } from '../../../../components/common/CommonQuestionReviewModal';
import CommonButton from '../../../../components/primitives/CommonButton';
import { FormSchemaType } from '../../form.types';
import { ADD_FORM_ELEMENTS } from '../../graphql/mutations';
import { CommonPageTypes } from './pages.types';

export const AddQuestion: React.FC<
  CommonPageTypes & {
    onManualQuestionAdd: () => void;
    onAIQuestionAdd: (data: FormSchemaType[]) => void;
    formId: string;
  }
> = ({ onBack, onManualQuestionAdd, onAIQuestionAdd, formId }) => {
  const [generateAIQuestionMutate, { loading }] = useMutation(
    ADD_FORM_ELEMENTS,
    {
      fetchPolicy: 'network-only',
      onError: () => {},
    },
  );
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
  const [structureList, setStructureList] = useState<FormSchemaType[]>([]);
  const [form] = useForm();

  const handleFinish = ({ prompt }: { prompt: string }) => {
    generateAIQuestionMutate({
      variables: {
        data: {
          prompt,
        },
        where: {
          formId,
        },
      },
      onCompleted: (res) => {
        const data = res.addFormElements?.data;
        if (!isEmpty(data?.structure)) {
          setStructureList(data?.structure || []);
          setIsReviewModalVisible(true);
        } else {
          form.setFields([
            { name: 'prompt', errors: [TOAST_MESSAGE.noQuestionGenerated] },
          ]);
        }
      },
      onError: (error) => {
        form.setFields([{ name: 'prompt', errors: [error?.message] }]);
      },
    });
  };
  return (
    <section>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: 'var(--surface-secondary)',
            colorPrimary: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
            },
            Input: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
            },
          },
        }}
      >
        <Flex gap={12} wrap align="center" className="scroll-wrapper-header">
          <CommonButton
            type="text"
            onClick={() => onBack()}
            shape="circle"
            shadow={false}
            icon={<CaretLeft color="var(--content-primary)" size={20} />}
          />
          <Paragraph className="mb-0 text-content-primary text-base semi-bold">
            Add Question
          </Paragraph>
        </Flex>
        <div className="scroll-wrapper-body">
          <Form onFinish={handleFinish} form={form}>
            <Form.Item
              label="What information would you like to collect?"
              name="prompt"
              labelCol={{ span: 24 }}
              required={true}
              rules={[formValidation.required, formValidation.whitespace]}
            >
              <Input.TextArea
                placeholder="e.g. Request current photo for security badge/employee id"
                autoSize={{ minRows: 3 }}
              />
            </Form.Item>
            <Form.Item>
              <Flex justify="flex-end" className="mt-8">
                <CommonButton
                  icon={<Sparkle size={16} weight="fill" />}
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                >
                  {loading ? 'Generating...' : 'Generate'}
                </CommonButton>
              </Flex>
            </Form.Item>
          </Form>
          <div className="mt-24 mb-24">
            <Divider dashed>
              <Paragraph className="mb-0 medium text-content-secondary">
                OR
              </Paragraph>
            </Divider>
          </div>
          <div>
            <CommonButton
              type="text"
              block
              icon={<Plus size={14} color="var(--content-primary)" />}
              onClick={() => {
                onManualQuestionAdd();
              }}
              disabled={loading}
            >
              Add Question Manually
            </CommonButton>
          </div>
        </div>
      </ConfigProvider>
      <CommonQuestionReviewModal
        visible={isReviewModalVisible}
        onClose={() => setIsReviewModalVisible(false)}
        onSubmit={(configs: FormSchemaType[]) => {
          onAIQuestionAdd(configs);
          setIsReviewModalVisible(false);
        }}
        options={structureList}
      />
    </section>
  );
};
