import {
  ConfigProvider,
  Switch,
  SwitchProps,
  ThemeConfig,
  Tooltip,
} from 'antd';
import { COMING_SOON_TEXT } from '../../common/constants';

type SwitchType = 'primary' | 'secondary';

export default function CommonSwitch({
  type,
  isTooltip = false,
  ...restProps
}: SwitchProps & { type: SwitchType; isTooltip?: boolean }) {
  const config: Record<string, ThemeConfig> = {
    primary: {
      token: {
        colorPrimary: '#21282B',
        colorPrimaryHover: 'var(--surface-inverse-primary)',
      },
      components: {
        Switch: {
          handleBg: 'var(--surface-primary)',
        },
      },
    },
    secondary: {
      token: {
        colorPrimary: '#E5E8E9',
        colorPrimaryHover: 'var(--surface-primary)',
      },
      components: {
        Switch: {
          handleBg: 'var(--surface-primary)',
        },
      },
    },
  };

  return (
    <ConfigProvider theme={config?.[type]}>
      <Tooltip title={isTooltip ? COMING_SOON_TEXT : ''}>
        <Switch {...restProps} className={type} />
      </Tooltip>
    </ConfigProvider>
  );
}
