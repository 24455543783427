import { Plus } from '@phosphor-icons/react';
import { ConfigProvider, Menu, MenuProps } from 'antd';
import { isEmpty, map } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { Workspace } from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import CommonWorkspaceCreateModal from '../../components/common/CommonWorkspaceCreateModal';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
type MenuItem = Required<MenuProps>['items'][number];

export default function ProfileWrapper() {
  const { navigate, location, params } = useRouter();
  const [menuList, setMenuList] = useState<MenuProps['items']>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [defaultActiveKey, setDefaultActiveKey] = useState<string[]>([]);
  const {
    dispatch,
    state: { workspaceList },
    updateCurrentUser,
  } = useContext(AppContext) as AppContextType;

  const handleConfirm = (workspace: Workspace) => {
    setIsVisible(false);
    dispatch({
      type: AppActionType.userWorkspaces,
      data: [workspace, ...workspaceList],
    });
    if (workspace?.uuid) {
      updateCurrentUser(workspace?.uuid, () => {
        navigate(`${ROUTES.PLAN_BILLING}/${workspace.uuid}`);
      });
    }
  };

  const updateMenuList = () => {
    const nameObj: Record<string, string> = {};
    const workspaceListItems = map(workspaceList, (item) => {
      if (item?.uuid) {
        nameObj[item.uuid] = item?.name || '';
      }
      return {
        key: item?.uuid || '',
        label: item?.name,
        children: [
          {
            key: ROUTES.INTEGRATION,
            label: 'Integrations',
            disabled: true,
          },
          {
            key: `${ROUTES.WORKSPACE_SETTING}/${item?.uuid}`,
            label: 'Workspace settings',
          },

          {
            key: `${ROUTES.WORKSPACE_MEMBERS}/${item?.uuid}`,
            label: 'Workspace members',
          },
          {
            key: `${ROUTES.PLAN_BILLING}/${item?.uuid}`,
            label: 'Plan & billing',
          },
        ],
      };
    });
    setMenuList([
      {
        key: ROUTES.ACCOUNT,
        label: 'My Account',
      },
      {
        key: 'CREATE_WORKSPACE',
        label: 'Create new workspace',
        icon: (
          <Plus size={16} color="var(--content-primary)" weight="regular" />
        ),
      },
      ...workspaceListItems,
    ]);
  };

  useEffect(() => {
    if (!isEmpty(workspaceList)) {
      updateMenuList();
    }
  }, [workspaceList]);

  useEffect(() => {
    if (params?.id) {
      setDefaultActiveKey([params.id]);
    }
  }, [params]);

  return (
    <div className="account-wrapper">
      <div className="account-nav">
        <ConfigProvider
          theme={{
            components: {
              Menu: {
                groupTitleColor: 'var(--content-primary)',
                itemBg: 'var(--surface-primary)',
                margin: 16,
                itemHoverColor: 'var(--content-primary)',
                itemSelectedColor: 'var(--content-primary)',
                itemHoverBg: 'var(--surface-secondary)',
                itemSelectedBg: 'var(--surface-secondary)',
                itemActiveBg: 'var(--surface-secondary)',
                itemColor: 'var(--content-primary)',
                padding: 24,
                subMenuItemBg: 'var(--surface-primary)',
                fontSize: 16,
                colorSplit: 'transparent',
                itemBorderRadius: 12,
              },
            },
          }}
        >
          <Menu
            selectedKeys={[location.pathname]}
            mode="inline"
            onClick={(info) => {
              if (info?.key === 'CREATE_WORKSPACE') {
                setIsVisible(true);
              } else {
                navigate(info.key);
              }
            }}
            items={menuList}
            onOpenChange={(keys) => {
              setDefaultActiveKey(keys);
            }}
            openKeys={defaultActiveKey}
          />
        </ConfigProvider>
      </div>
      <div className="account-plan-detail">
        <Outlet />
      </div>
      {isVisible && (
        <CommonWorkspaceCreateModal
          isVisible={isVisible}
          onConfirm={(workspace) => {
            handleConfirm(workspace);
          }}
          onCancel={() => {
            setIsVisible(false);
          }}
          title="Create new workspace"
        />
      )}
    </div>
  );
}
