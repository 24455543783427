import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { AppContextType } from '../../types/appContext.type';
import CommonButton from '../primitives/CommonButton';

const PermissionPageAccess = () => {
  const { getWorkspaceId } = useContext(AppContext) as AppContextType;
  return (
    <Flex className="max-h-screen" vertical align="center" gap={32}>
      <Flex vertical gap={8}>
        <Title level={2} className="mb-0 font-secondary text-center">
          Access Restricted
        </Title>
        <Paragraph className="mb-0 text-content-secondary text-center max-w-800">
          You only have view access for this workspace. To create new forms,
          please request edit permissions from the workspace admin.
        </Paragraph>
      </Flex>
      <Link to={`${ROUTES.MAIN}${getWorkspaceId()}`}>
        <CommonButton type="primary">Return to Home</CommonButton>
      </Link>
    </Flex>
  );
};

export default PermissionPageAccess;
