import { useQuery } from '@apollo/client';
import {
  CheckCircle,
  CopySimple,
  FacebookLogo,
  LinkedinLogo,
  QrCode,
  TwitterLogo,
} from '@phosphor-icons/react';
import { Col, Divider, Empty, Flex, Input, Modal, Row, Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { filter, isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { FormShareType } from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import {
  initialPaginationFilter,
  initialSort,
  ROUTES,
} from '../../common/constants';
import {
  copyToClipboard,
  getFormLink,
  handleSocialShare,
} from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import FormLoading from '../../modules/form/editor/utils/FormLoading';
import { SocialShareType } from '../../modules/form/form.types';
import { GET_FORM_SHARES } from '../../modules/form/graphql/queries';
import { AppContextType } from '../../types/appContext.type';
import CommonButton from '../primitives/CommonButton';
import CommonQRCodeModal from './CommonQRCodeModal';

type PublishSuccessModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const PublishSuccessModal: React.FC<PublishSuccessModalProps> = ({
  isVisible,
  onCancel,
  onConfirm,
}) => {
  const {
    state: { currentUser, formSettings },
  } = useContext(AppContext) as AppContextType;

  const { params, navigate } = useRouter();
  const formId = params?.id as string;
  const [link, setLink] = useState<string>('');
  const [qrModalVisible, setQrModalVisible] = useState<boolean>(false);

  const { loading, data } = useQuery(GET_FORM_SHARES, {
    variables: {
      where: {
        formId,
      },
      filter: initialPaginationFilter,
      sort: initialSort,
    },
    onError() {},
    fetchPolicy: 'network-only',
  });

  const handleQRCode = () => {
    setQrModalVisible(true);
  };

  const shareLink = filter(
    data?.formShares?.data,
    (share) => share?.type === FormShareType.Link,
  )[0];

  const embedLink = filter(
    data?.formShares?.data,
    (share) => share?.type === FormShareType.Embed,
  )[0];

  useEffect(() => {
    if (shareLink) {
      setLink(getFormLink(shareLink?.key || ''));
    }
  }, [shareLink]);

  const handleRedirect = () => {
    onCancel();
    navigate(`${ROUTES.SHARE}/${formId}/${embedLink?.id}`);
  };

  return (
    <Modal
      title={null}
      open={isVisible}
      onCancel={onCancel}
      className="common-key-modal"
      footer={null}
    >
      <Flex vertical align="center" gap={16}>
        <CheckCircle
          width={96}
          height={96}
          color="var(--success-on-surface)"
          weight="fill"
        />
        <Flex vertical>
          <Title
            level={4}
            className="font-secondary text-content-primary text-center semi-bold"
          >
            Published Successfully
          </Title>
          <Paragraph className="text-sm text-content-secondary mb-0 text-center medium">
            Share your form to start collecting responses.
          </Paragraph>
        </Flex>
        {loading ? (
          <FormLoading />
        ) : isEmpty(shareLink) ? (
          <Empty description="No link found" />
        ) : (
          <Flex gap={12} vertical className="w-full" justify="center">
            <Row gutter={[12, 12]}>
              <Col span={16}>
                <Input placeholder="Link here..." value={link} disabled />
              </Col>
              <Col span={8}>
                <CommonButton
                  type="text"
                  icon={<CopySimple color="var(--content-primary)" size={16} />}
                  block
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard(link);
                  }}
                >
                  Copy Link
                </CommonButton>
              </Col>
              <Col span={24}>
                <Flex gap={12} justify="flex-start" align="center">
                  <Tooltip title="Share on Facebook">
                    <CommonButton
                      type="text"
                      shape="circle"
                      icon={<FacebookLogo size={24} />}
                      onClick={() =>
                        handleSocialShare(SocialShareType.FACEBOOK, link)
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Share on Twitter">
                    <CommonButton
                      type="text"
                      shape="circle"
                      icon={<TwitterLogo size={24} />}
                      onClick={() =>
                        handleSocialShare(SocialShareType.TWITTER, link)
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Share on LinkedIn">
                    <CommonButton
                      type="text"
                      shape="circle"
                      icon={<LinkedinLogo size={24} />}
                      onClick={() =>
                        handleSocialShare(SocialShareType.LINKEDIN, link)
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Generate QR Code">
                    <CommonButton
                      type="text"
                      shape="circle"
                      icon={<QrCode size={24} />}
                      onClick={handleQRCode}
                    />
                  </Tooltip>
                </Flex>
              </Col>
            </Row>

            {!isEmpty(embedLink) && (
              <>
                <Divider className="mb-0 mt-0" orientation="center">
                  <Paragraph className="text-meta text-content-quarternary mb-0 semi-bold">
                    OR
                  </Paragraph>
                </Divider>
                <Flex vertical align="flex-start" gap={8}>
                  <Paragraph className="text-content-secondary mb-0">
                    You can also embed your form in your website or application.
                  </Paragraph>
                  <CommonButton onClick={handleRedirect}>
                    Embed form
                  </CommonButton>
                </Flex>
              </>
            )}
          </Flex>
        )}
      </Flex>
      {qrModalVisible && (
        <CommonQRCodeModal
          qrModalVisible={qrModalVisible}
          handleCloseQRModal={() => setQrModalVisible(false)}
          link={link}
        />
      )}
    </Modal>
  );
};

export default PublishSuccessModal;
