import { useLazyQuery } from '@apollo/client';
import { DownloadSimple, Paperclip } from '@phosphor-icons/react';
import { Empty, Flex, Modal, ModalProps, Rate, Tag } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs from 'dayjs';
import { cloneDeep, includes, isArray, isEmpty, map, reverse } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  FormSubmission,
  ListSubmissionTranscriptsSortOnField,
  SortOrder,
  SubmissionTranscript,
} from '../../__generated__/graphql';
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_ITEMS_PER_PAGE,
  RESULT_DATE_FORMAT,
} from '../../common/constants';
import FormLoading from '../../modules/form/editor/utils/FormLoading';
import { FormFieldType } from '../../modules/form/form.types';
import { GET_FORM_TRANSCRIPTS } from '../../modules/form/graphql/queries';
import CommonButton from '../primitives/CommonButton';

export default function TranscriptModal({
  open,
  metaData,
  isFetch,
  ...rest
}: ModalProps & { metaData: FormSubmission; isFetch: boolean }) {
  const [initialFilter] = useState({
    filter: {
      limit: DEFAULT_ITEMS_PER_PAGE,
      skip: 0,
    },
    sort: [
      {
        sortBy: SortOrder.Desc,
        sortOn: ListSubmissionTranscriptsSortOnField.CreatedAt,
      },
    ],
  });

  const [hasMore, setHasMore] = useState(true);
  const [fetchData, { data, loading }] = useLazyQuery(GET_FORM_TRANSCRIPTS, {
    fetchPolicy: 'network-only',
  });

  const observer = useRef<IntersectionObserver | null>(null);

  const [transcriptList, setTranscriptList] = useState<SubmissionTranscript[]>(
    [],
  );

  useEffect(() => {
    if (metaData?.id) {
      if (isFetch) {
        fetchData({
          variables: {
            ...initialFilter,
            where: {
              submissionId: metaData?.id as string,
            },
          },
          onCompleted: (res) => {
            setHasMore(
              Number(res.submissionTranscripts?.count) > DEFAULT_ITEMS_PER_PAGE,
            );
            setTranscriptList(
              reverse(
                cloneDeep(
                  res.submissionTranscripts?.data as SubmissionTranscript[],
                ),
              ),
            );
          },
        });
      } else {
        setTranscriptList(
          metaData?.voiceConversation as SubmissionTranscript[],
        );
      }
    }
  }, [initialFilter, metaData]);

  // load more func when last element becomes visible
  const loadMore = () => {
    fetchData({
      variables: {
        ...initialFilter,
        filter: {
          limit: DEFAULT_ITEMS_PER_PAGE,
          skip: transcriptList.length,
        },
        where: {
          submissionId: metaData?.id as string,
        },
      },
      onCompleted: (res) => {
        const mergedData = [
          ...transcriptList,
          ...(res.submissionTranscripts?.data as SubmissionTranscript[]),
        ];
        setTranscriptList(mergedData);
        setHasMore(
          Number(res.submissionTranscripts?.count) > mergedData.length,
        );
      },
    });
  };

  const lastItemRef = (node: HTMLDivElement | null) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        loadMore();
      }
    });
    if (node) observer.current.observe(node);
  };

  const UserResponseText = ({ text }: { text: string }) => {
    return (
      <Paragraph className="mb-0 medium text-content-secondary">
        {text}
      </Paragraph>
    );
  };

  const getTranscriptBody = (formSchema: SubmissionTranscript) => {
    switch (formSchema?.type) {
      case FormFieldType.DATE:
        return dayjs(formSchema?.userResponse?.value).format(
          RESULT_DATE_FORMAT,
        );
      case FormFieldType.SHORT_TEXT:
      case FormFieldType.LONG_TEXT:
      case FormFieldType.EMAIL:
      case FormFieldType.NUMBER:
      case FormFieldType.PHONE_NUMBER:
      case FormFieldType.TIME:
      case FormFieldType.ADDRESS:
      case FormFieldType.CONTACT_INFO:
        return formSchema?.userResponse?.value || '-';
      case FormFieldType.FILE:
        return (
          <Flex gap={8} wrap>
            {isArray(formSchema?.userResponse?.value) &&
              map(formSchema?.userResponse?.value, (fileLink, idx) => {
                return (
                  <Tag
                    bordered={false}
                    className="download-btn text-content-primary"
                  >
                    <Paperclip size={14} color="var(--content-primary)" />
                    {`File ${idx + 1}`}
                    <DownloadSimple size={14} color="var(--content-primary)" />
                  </Tag>
                );
              })}
          </Flex>
        );
      case FormFieldType.LINEAR_SCALE:
        return (
          <UserResponseText text={formSchema?.userResponse?.value || '-'} />
        );
      case FormFieldType.SELECT:
      case FormFieldType.MULTI_SELECT:
        return (
          <Flex gap={8}>
            {map(formSchema.options, (opt) => {
              return (
                <CommonButton
                  type={
                    includes(formSchema?.userResponse?.value, opt)
                      ? 'primary'
                      : 'text'
                  }
                  size="small"
                >
                  {opt}
                </CommonButton>
              );
            })}
          </Flex>
        );
      case FormFieldType.RATE:
        return (
          <Rate
            defaultValue={Number(formSchema?.userResponse?.value)}
            disabled
          />
        );
      default:
        return formSchema?.userResponse?.value || '-';
    }
  };

  return (
    <Modal
      open={open}
      title={
        <div className="mb-24">
          <div className="text-lg medium text-content-primary mb-2">
            Transcript
          </div>
          <p className="text-sm text-content-secondary mt-4">
            {dayjs(metaData?.createdAt).format(DEFAULT_DATE_TIME_FORMAT)}
          </p>
        </div>
      }
      className="transcript-modal"
      {...rest}
    >
      <div>
        {!isEmpty(transcriptList) ? (
          map(transcriptList, (item, idx) => {
            return (
              <div
                className="mb-32"
                ref={
                  idx === transcriptList.length - 1 && isFetch
                    ? lastItemRef
                    : null
                }
              >
                <div className="text-base medium text-content-primary">
                  {item?.aiResponse}
                </div>
                <div className="mt-8">{getTranscriptBody(item)}</div>
              </div>
            );
          })
        ) : (
          <Empty />
        )}
        {loading && <FormLoading />}
      </div>
    </Modal>
  );
}
