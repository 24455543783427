import { gql } from '../../../__generated__';

export const GET_SIGNED_URL = gql(`
 query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {
  getProfileImageUploadSignedUrl(data: $data) {
    key
    signedUrl
  }}`);

export const GET_PLAN_LIST = gql(`
   query getPlanList($sort: [ListSubscriptionPlanSort!]) {
  subscriptionPlanKeyFeatures(sort: $sort) {
    data {
      id
      keyFeatures
      description
      price
      currency
      type
      title
      label
      recurring
      isSubscribed
      slug
    }
  }
}`);

export const GET_FEATURE_LIST = gql(`
  query SubscriptionPlans($sort: [ListSubscriptionPlanSort!]) {
  subscriptionPlans(sort: $sort) {
    data {
      id
      label
      isSubscribed
      features
      currency
      price
      categoryWiseGroupedFeatures {
        categoryKey
        list {
          title
          subCategoryKey
          features {
            value
            title
            key
            featureLabel
            description
            isVisible
            comingSoon
          }
        }
        title
      }
    }
  }
}`);
