import Paragraph from 'antd/es/typography/Paragraph';

export default function CustomIndicator({
  current,
  total,
}: {
  current: number;
  total: number;
}) {
  return (
    <Paragraph className="mb-0 text-sm">
      {current + 1} / {total}
    </Paragraph>
  );
}
