import { useQuery } from '@apollo/client';
import { Segmented, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import {
  ListFormSubmissionsSortOnField,
  SortOrder,
} from '../../__generated__/graphql';
import { COMING_SOON_TEXT, ROUTES } from '../../common/constants';
import PremiumLogo from '../../components/common/PremiumLogo';
import useRouter from '../../hooks/useRouter';
import { GET_RESULT_BY_PARTICIPANTS } from '../form/graphql/queries';
import ByParticipants from './ByParticipants';
import ByQuestions from './ByQuestions';
import Overview from './Overview';
import Settings from './Settings';
import { FormResultBy } from './result.types';

export default function Results() {
  const [resultType, setResultType] = useState<FormResultBy>();
  const { navigate, params } = useRouter();
  const formId = params?.id as string;
  const type = params?.type as FormResultBy;

  const [initialFilter] = useState({
    filter: {
      isTest: false,
      isCompleted: true,
      limit: 10,
      skip: 0,
    },
    where: {
      formId,
    },
    sort: [
      {
        sortBy: SortOrder.Desc,
        sortOn: ListFormSubmissionsSortOnField.CreatedAt,
      },
    ],
  });

  const { data } = useQuery(GET_RESULT_BY_PARTICIPANTS, {
    fetchPolicy: 'network-only',
    variables: initialFilter,
  });

  useEffect(() => {
    setResultType(type || FormResultBy.Overview);
  }, [type]);

  const onChange = (type: FormResultBy) => {
    navigate(`${ROUTES.RESULTS}/${type}/${formId}`);
  };

  const renderSegmentedContent = () => {
    switch (resultType) {
      case FormResultBy.Overview:
        return <Overview />;
      case FormResultBy.ByQuestion:
        return <ByQuestions />;
      case FormResultBy.ByParticipants:
        return <ByParticipants />;
      case FormResultBy.PartiallyFilled:
        return <Settings />;
      default:
        setResultType(FormResultBy.Overview);
        return <Overview />;
    }
  };

  return (
    <section className="editor-wrapper result-page">
      {/* <Tabs activeKey={pathname} items={items} onChange={onChange} centered /> */}
      {/* <SubscribeModal /> */}
      {/* <UpgradeSuccessful /> */}
      <div className="result-segments-wrapper mb-16">
        <Segmented
          className="app-segments"
          value={resultType}
          options={[
            {
              value: FormResultBy.Overview,
              label: 'Overview',
            },
            {
              value: FormResultBy.ByQuestion,
              label: 'By Questions',
            },
            {
              value: FormResultBy.ByParticipants,
              label: `By Participants (${data?.formSubmissions?.count || 0})`,
            },
            {
              value: FormResultBy.PartiallyFilled,
              label: (
                <Tooltip title={COMING_SOON_TEXT}>Partially filled</Tooltip>
              ),
              icon: <PremiumLogo isTooltip />,
              disabled: true,
            },
          ]}
          onChange={(value) => {
            onChange(value);
          }}
        />
      </div>
      <div className="segment-content">{renderSegmentedContent()}</div>
    </section>
  );
}
