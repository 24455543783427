import { Warning } from '@phosphor-icons/react';
import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';

export default function DeleteShareMessage() {
  return (
    <Flex align="center" gap={8}>
      <Warning size={18} weight="fill" color="var(--caution-on-surface)" />
      <Flex vertical>
        <Paragraph className="mb-0">
          This will deactivate already shared links.
        </Paragraph>
        <Paragraph className="mb-0">Delete anyway?</Paragraph>
      </Flex>
    </Flex>
  );
}
