export type ChildrenPropType = {
  children: React.ReactNode;
};

export type UserData = {
  firstName?: string;
  lastName?: string;
  roles?: string[];
};

export type ChildrenType = {
  children?: React.ReactNode;
};

export type ID = string | number;

export type MetaTypes = Record<string, string>;

export enum Permissions {
  READ = 'read',
  WRITE = 'write',
  OWNER = 'owner',
}
