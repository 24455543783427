import { Desktop } from '@phosphor-icons/react';
import { Flex, Grid } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import React from 'react';
import { ChildrenType } from '../../types/common.type';
import ScreenLayout from '../layouts/ScreenLayout';

const { useBreakpoint } = Grid;

export const MobileWarning: React.FC<ChildrenType> = ({ children }) => {
  const screens = useBreakpoint();

  if (!screens.lg) {
    return (
      <ScreenLayout>
        <div className="container">
          <Flex vertical gap={12} justify="center" align="center">
            <Desktop size={64} color="var(--content-secondary)" weight="thin" />
            <Paragraph className="text-center">
              Optimized for desktop use. Please switch to a larger screen for
              the best experience.
            </Paragraph>
          </Flex>
        </div>
      </ScreenLayout>
    );
  }
  return <>{children}</>;
};
