import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import ResizableLayout from '../../../components/layouts/ResizableLayout';
import useRouter from '../../../hooks/useRouter';
import { FormSchemaType } from '../form.types';
import { GET_FORM_STRUCTURES } from '../graphql/queries';
import FormPreviewMode from './FormPreviewMode';
import { FormSharing } from './pages/FormSharing';

export default function Share() {
  const [activeKey, setActiveKey] = useState<string>();
  const [itemList, setItemList] = useState<FormSchemaType[]>([]);
  const { params } = useRouter();
  const formId = params?.id as string;

  const [fetchFormStructures, { loading }] = useLazyQuery(GET_FORM_STRUCTURES, {
    fetchPolicy: 'network-only',
    variables: {
      where: { formId },
    },
    onCompleted: (res) => {
      setItemList(
        res.form?.data?.structures?.[0]?.structure as FormSchemaType[],
      );
      setActiveKey(res.form?.data?.structures?.[0]?.structure?.[0].question_id);
    },
  });

  useEffect(() => {
    fetchFormStructures();
  }, []);

  return (
    <section className="editor-wrapper">
      <ResizableLayout
        leftContent={<FormSharing />}
        rightContent={<FormPreviewMode showTabs={false} />}
        minPercentage={40}
        maxPercentage={60}
      />
    </section>
  );
}
