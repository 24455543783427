import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { App, ConfigProvider } from 'antd';
import Gleap from 'gleap';
import { PostHogProvider } from 'posthog-js/react';
import ReactDOM from 'react-dom/client';
import 'react-quill/dist/quill.snow.css';
import '../src/styles/main.scss';
import { AppContextProvider } from './AppContext';
import RoutesWrapper from './RoutesWrapper';
import client from './apollo';
import AppVersion from './components/common/AppVersion';
import ConnectionMode from './components/common/ConnectionMode';
import CookieConsent from './components/common/CookieConsent';
import DetectSystemThemeMode from './components/common/DetectSystemThemeMode';
import { MobileWarning } from './components/common/MobileWarning';
import AppContextHolder from './components/context/AppContextHolder';
import themeConfig from './styles/theme/themeConfig';

const postHogConfigs = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  // additional configs
  // capture_pageview: false,
  // capture_pageleave: false,
  // autocapture: false,
};

if (process.env.REACT_APP_ENV !== 'localhost') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
  });
  // initialize gleap for feedback
  Gleap.initialize(process.env.REACT_APP_GLEAP_KEY!);
}

// use this variable from envs so that we can active connection mode feature in app.
const connectionMode = process.env.REACT_APP_CONNECTION_MODE_ENABLE;

// use this variable from envs so that we can active cookie consent mode feature in app.
const cookieMode = process.env.REACT_APP_COOKIE_CONSENT_ENABLE;

// eslint-disable-next-line no-undef
const root = ReactDOM?.createRoot(
  document?.getElementById('root') as HTMLElement,
);

root?.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={postHogConfigs}
  >
    <ApolloProvider client={client}>
      <AppContextProvider>
        <App>
          {/* Adding toast-container to render toast messages [ant v5.19.4] */}
          <AppContextHolder />
          <ConfigProvider theme={themeConfig} prefixCls="zinq">
            {/* Adding connection mode to notify user when they are offline/online */}
            {connectionMode === 'true' && <ConnectionMode />}
            {/* Adding cook
          ie consent component to inform users about cookies we use */}
            {cookieMode === 'true' && <CookieConsent />}
            <MobileWarning>
              <RoutesWrapper />
            </MobileWarning>
          </ConfigProvider>
          <AppVersion />
          <DetectSystemThemeMode />
        </App>
      </AppContextProvider>
    </ApolloProvider>
  </PostHogProvider>,
);
