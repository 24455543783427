import { useLazyQuery } from '@apollo/client';
import {
  CaretDown,
  CaretLeft,
  Gear,
  Plus,
  UserCircle,
} from '@phosphor-icons/react';
import { Avatar, ConfigProvider, Flex, Select, Skeleton, Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import Paragraph from 'antd/es/typography/Paragraph';
import { includes, isEmpty, map } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  SortOrder,
  Workspace,
  WorkspaceSortField,
} from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { getInitials, hasFeatureAccess } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { FeatureKeys } from '../../modules/profile/profile.types';
import { GET_USERS_WORKSPACES } from '../../modules/workspace/graphql/queries';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { Permissions } from '../../types/common.type';
import CommonButton from '../primitives/CommonButton';
import CommonWorkspaceCreateModal from './CommonWorkspaceCreateModal';
import GleapBotPopup from './gleap/GleapBotPopup';

export default function AppHeader() {
  const {
    state: { currentUser, workspaceList },
    dispatch,
    getWorkspaceId,
    hasPermission,
    updateCurrentUser,
  } = useContext(AppContext) as AppContextType;
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<Record<string, string>>(
    {},
  );

  const {
    location: { pathname },
    params,
    navigate,
  } = useRouter();

  const hasInviteAccess =
    Number(
      hasFeatureAccess(
        currentUser?.subscriptionPlan?.features,
        FeatureKeys.MAX_WORKSPACE_MEMBER,
      ),
    ) > 1;

  const ownerAccess = hasPermission(Permissions.OWNER);

  const [menuList, setMenuList] = useState<DefaultOptionType[]>([]);

  const [fetchWorkspaces, { loading }] = useLazyQuery(GET_USERS_WORKSPACES, {
    fetchPolicy: 'network-only',
    onError: () => {},
    onCompleted: (res) => {
      dispatch({
        type: AppActionType.userWorkspaces,
        data: (res.workspaces?.data as Workspace[]) || [],
      });
    },
  });

  useEffect(() => {
    dispatch({
      type: AppActionType.setAppState,
      data: {
        formFetchLoading: false,
        formCreateError: false,
        formCreateLoading: false,
      },
    });
  }, []);

  const updateMenuList = () => {
    const nameObj: Record<string, string> = {};
    const workspaceListItems = map(workspaceList, (item) => {
      if (item?.uuid) {
        nameObj[item.uuid] = item?.name || '';
        nameObj[`role-${item.uuid}`] = item?.workspaceMembers?.[0]?.role || '';
      }
      return {
        label: (
          <Flex align="center" gap={4}>
            <Paragraph className="mb-0 text-base">{item?.name}</Paragraph>
            <Gear
              size={16}
              color="var(--content-primary)"
              weight="regular"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${ROUTES.WORKSPACE_SETTING}/${item?.uuid}`);
              }}
            />
          </Flex>
        ),
        value: item!.uuid!,
        filterValue: item?.name,
      };
    });
    setSelectedValue(nameObj);
    setMenuList([
      ...workspaceListItems,
      {
        label: (
          <Flex align="center" gap={8}>
            <Plus size={16} color="var(--content-primary)" weight="regular" />
            <Paragraph className="mb-0 text-base">
              Create new workspace
            </Paragraph>
          </Flex>
        ),
        value: 'CREATE_WORKSPACE',
        filterValue: 'Create new workspace',
      },
    ]);
  };

  useEffect(() => {
    if (isEmpty(workspaceList)) {
      fetchWorkspaces({
        variables: {
          sort: {
            sortBy: SortOrder.Desc,
            sortOn: WorkspaceSortField.CreatedAt,
          },
        },
      });
    } else {
      updateMenuList();
    }
  }, [workspaceList]);

  const hiddenRoutesForProfile = [ROUTES.FORM_CREATE, ROUTES.FORM_TEMPLATES];

  const handleWorkspaceClick = (key: string) => {
    if (key === 'CREATE_WORKSPACE') {
      setIsVisible(true);
    } else {
      updateCurrentUser(key, () => {
        navigate(`${ROUTES.MAIN}${key}`);
      });
    }
  };

  const handleConfirm = (workspace: Workspace) => {
    setIsVisible(false);
    dispatch({
      type: AppActionType.userWorkspaces,
      data: [workspace, ...workspaceList],
    });
    if (workspace?.uuid) {
      updateCurrentUser(workspace?.uuid, () => {
        navigate(`${ROUTES.MAIN}${workspace?.uuid}`);
      });
    }
  };

  const handleInvite = () => {
    if (hasInviteAccess) {
      navigate(`${ROUTES.WORKSPACE_MEMBERS}/${params?.workspaceId}`);
    } else {
      navigate(`${ROUTES.PLAN_BILLING}/${params?.workspaceId}`);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            selectorBg: 'transparent',
            colorBorder: 'transparent',
          },
        },
      }}
    >
      <div className="app-header">
        <Space>
          {!params?.workspaceId ? (
            <Link to={`${ROUTES.MAIN}${getWorkspaceId()}`}>
              <Flex align="center" gap={8}>
                <CaretLeft size={16} color="var(--content-tertiary)" />
                <Paragraph className="mb-0 text-content-tertiary">
                  Back
                </Paragraph>
              </Flex>
            </Link>
          ) : params?.workspaceId ? (
            !loading ? (
              <>
                <Select
                  className="menu-list"
                  value={params?.workspaceId}
                  options={menuList}
                  onChange={(value) => {
                    handleWorkspaceClick(value);
                  }}
                  labelRender={(props) => (
                    <Paragraph className="mb-0 text-content-secondary text-base semi-bold">
                      {selectedValue[props?.value]}
                    </Paragraph>
                  )}
                  showSearch
                  optionFilterProp="filterValue"
                  suffixIcon={
                    <CaretDown color="var(--content-secondary)" size={20} />
                  }
                  getPopupContainer={() =>
                    document.getElementById(
                      'workspace-menu-list',
                    ) as HTMLElement
                  }
                />
                <div id="workspace-menu-list" />
              </>
            ) : (
              <Skeleton.Input active />
            )
          ) : (
            <div />
          )}
        </Space>
        <Flex align="center" justify="space-between" gap={12}>
          {!includes(hiddenRoutesForProfile, pathname) && (
            <>
              <GleapBotPopup />
              {!pathname?.includes(ROUTES.ACCOUNT) && ownerAccess && (
                <CommonButton
                  icon={
                    <UserCircle
                      size={14}
                      color="var(--content-primary)"
                      weight="fill"
                    />
                  }
                  premium={!hasInviteAccess}
                  type="text"
                  onClick={handleInvite}
                >
                  Invite
                </CommonButton>
              )}
              <Link to={ROUTES.ACCOUNT}>
                <Space>
                  {currentUser?.profileImage ? (
                    <Avatar
                      size="large"
                      src={<img src={currentUser?.profileImage} alt="avatar" />}
                    />
                  ) : (
                    <Avatar size="large">
                      {getInitials(currentUser?.name || 'User')}
                    </Avatar>
                  )}
                </Space>
              </Link>
            </>
          )}
        </Flex>
        {isVisible && (
          <CommonWorkspaceCreateModal
            isVisible={isVisible}
            onConfirm={(workspace) => {
              handleConfirm(workspace);
            }}
            onCancel={() => {
              setIsVisible(false);
            }}
            title="Create new workspace"
          />
        )}
      </div>
    </ConfigProvider>
  );
}
