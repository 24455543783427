import { useMutation } from '@apollo/client';
import { CaretDown } from '@phosphor-icons/react';
import { Flex, Form, Modal, Select } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { WorkspaceMemberRoles } from '../../__generated__/graphql';
import {
  DROPDOWN_LIST,
  MODAL_SIZE,
  roleListMapping,
  TOAST_MESSAGE,
} from '../../common/constants';
import {
  formValidation,
  handleGraphQlSuccess,
  validateEmails,
} from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { INVITE_USER_WORKSPACE } from '../../modules/workspace/graphql/mutations';
import CommonButton from '../primitives/CommonButton';

type InviteMembersModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
};

const InviteMembersModal: React.FC<InviteMembersModalProps> = ({
  isVisible,
  onCancel,
  onConfirm,
  title,
}) => {
  const [form] = Form.useForm();
  const { params } = useRouter();
  const [inviteUserMutate, { loading }] = useMutation(INVITE_USER_WORKSPACE, {
    onError: () => {},
  });

  const handleConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        const emails = form.getFieldValue('emails');
        if (!validateEmails(emails)) {
          form.setFields([
            {
              name: 'emails',
              errors: [TOAST_MESSAGE.invalidEmails],
            },
          ]);
        } else {
          inviteUserMutate({
            variables: {
              data: values,
              where: {
                workspaceUuid: params?.id as string,
              },
            },
            onCompleted: (res) => {
              handleGraphQlSuccess(res.inviteUserInWorkspace?.message);
              onConfirm();
            },
            onError: (error) => {
              form.setFields([{ name: 'emails', errors: [error?.message] }]);
            },
          });
        }
      })
      .catch(() => {
        // do nothing
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const ModalTitle = () => (
    <Title level={4} className="font-secondary text-content-primary">
      {title}
    </Title>
  );

  return (
    <Modal
      className="invite-member-modal"
      title={<ModalTitle />}
      open={isVisible}
      onCancel={handleCancel}
      width={MODAL_SIZE.large}
      footer={[
        <CommonButton key="cancel" onClick={handleCancel} disabled={loading}>
          Cancel
        </CommonButton>,
        <CommonButton
          key="confirm"
          type="primary"
          htmlType="submit"
          onClick={handleConfirm}
          loading={loading}
        >
          Invite
        </CommonButton>,
      ]}
    >
      <Flex vertical align="flex-start" gap={16}>
        <Form
          form={form}
          layout="vertical"
          className="w-full"
          initialValues={{
            role: WorkspaceMemberRoles.Editor,
          }}
        >
          <Form.Item
            name="emails"
            label="Emails"
            rules={[formValidation.required]}
          >
            <Select
              mode="tags"
              placeholder="Type an email and press Enter to add"
              className="emails-list"
              open={false}
              suffixIcon={null}
              allowClear
            />
          </Form.Item>
          <Form.Item name="role" label="Invite as">
            <Select
              className="role-list"
              suffixIcon={
                <CaretDown size={16} color="var(--content-tertiary)" />
              }
              options={DROPDOWN_LIST.roleList}
              optionRender={(option) => (
                <Flex vertical gap={2}>
                  <Paragraph className="text-base medium mb-0">
                    {option?.label}
                  </Paragraph>
                  <Paragraph className="mb-0 white-space-normal text-content-tertiary text-sm">
                    {
                      roleListMapping?.[
                        option?.data.value as WorkspaceMemberRoles
                      ]
                    }
                  </Paragraph>
                </Flex>
              )}
              getPopupContainer={() =>
                document.getElementById('role-list-dropdown') as HTMLElement
              }
            />
          </Form.Item>
        </Form>
      </Flex>
    </Modal>
  );
};

export default InviteMembersModal;
