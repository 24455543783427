import { Flex, Form, Modal } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { hasFeatureAccess } from '../../common/utils';
import { FeatureKeys } from '../../modules/profile/profile.types';
import { AppContextType } from '../../types/appContext.type';
import CommonButton from '../primitives/CommonButton';

type CommonFormLimitModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const CommonFormLimitModal: React.FC<CommonFormLimitModalProps> = ({
  isVisible,
  onCancel,
  onConfirm,
}) => {
  const [form] = Form.useForm();
  const {
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;

  const formLimit =
    hasFeatureAccess(
      currentUser?.subscriptionPlan?.features,
      FeatureKeys.FORM_CREATION_LIMIT,
    ) ?? 0;

  const ModalTitle = () => (
    <Title level={4} className="font-secondary text-content-primary">
      Form limit reached!
    </Title>
  );

  return (
    <Modal
      title={<ModalTitle />}
      open={isVisible}
      onCancel={onCancel}
      className="common-key-modal"
      footer={[
        <CommonButton
          key="confirm"
          type="primary"
          onClick={() => {
            onConfirm();
          }}
        >
          Upgrade to premium
        </CommonButton>,
      ]}
    >
      <Flex vertical align="flex-start" gap={16}>
        <Paragraph className="text-sm text-content-secondary mb-0">
          {`You've hit your limit of ${formLimit < 0 ? 'Inifinity' : formLimit} forms. Upgrade to Premium for unlimited
          form creation and more features.`}
        </Paragraph>
      </Flex>
    </Modal>
  );
};

export default CommonFormLimitModal;
