import { gql } from '../../../__generated__';

export const CREATE_USER_WORKSPACE = gql(`
mutation CreateWorkspace($data: CreateWorkspaceInput!) {
  createWorkspace(data: $data) {
    message
    data {
      id
      uuid
      name
    }
  }
}`);

export const UPDATE_WORKSPACE_SETTINGS = gql(`
mutation UpdateWorkspace($data: UpdateWorkspaceInput!, $where: WorkspaceUniqueUuidInput!) {
  updateWorkspace(data: $data, where: $where) {
    message
  }
}`);

export const DELETE_USER_WORKSPACE = gql(`
  mutation DeleteWorkspace($where: WorkspaceUniqueUuidInput!) {
  deleteWorkspace(where: $where) {
    message
  }
}`);

export const INVITE_USER_WORKSPACE = gql(`
  mutation InviteUserInWorkspace($data: InviteWorkspaceMembersInput!, $where: WorkspaceUniqueUuidInput!) {
  inviteUserInWorkspace(data: $data, where: $where) {
    message
  }
}`);

export const DELETE_INVITE_MEMBER = gql(`
  mutation DeleteMember($where: WorkspaceMemberUniqueInput!) {
  deleteMember(where: $where) {
    message
  }
}`);

export const UPDATE_MEMBER_ROLE = gql(`
 mutation UpdateMemberRole($where: WorkspaceMemberUniqueInput!, $data: UpdateMemberRoleInput!) {
  updateMemberRole(where: $where, data: $data) {
    message
  }
}`);

export const RESEND_MEMBER_INVITE = gql(`
 mutation ResendInvitation($data: ResendInvitationInput!) {
  resendInvitation(data: $data) {
    message
  }
}`);

export const ACCEPT_WORKSPACE_INVITE = gql(`
 mutation AcceptInvitation($data: AcceptInvitationInput!) {
  acceptInvitation(data: $data) {
    message
  }
}`);
