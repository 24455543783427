import { Col, Row } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { includes } from 'lodash';
import React from 'react';
import { ROUTES } from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import AuthCarousel from '../../modules/auth/AuthCarousel';

export default function AuthLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { location, navigate } = useRouter();
  const isBackLink = includes(location.pathname, ROUTES.FORGET_PASSWORD);

  const handleBack = () => {
    navigate(ROUTES.LOGIN);
  };
  return (
    <Row className="auth-layout" gutter={0}>
      <Col span={24} lg={12}>
        <AuthCarousel />
      </Col>
      <Col span={24} lg={12}>
        {isBackLink && (
          <Paragraph
            className="absolute left-24 top-24 text-underline text-meta cursor-pointer medium"
            onClick={handleBack}
          >
            Back
          </Paragraph>
        )}
        <div className="auth-container">{children}</div>
      </Col>
    </Row>
  );
}
