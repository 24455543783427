import { Warning } from '@phosphor-icons/react';
import { Flex, Modal } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { MODAL_SIZE } from '../../common/constants';
import { AppContextType } from '../../types/appContext.type';
import CommonButton from '../primitives/CommonButton';

type CommonWorkspaceAlertModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: (workspaceId: string) => void;
};

const CommonWorkspaceAlertModal: React.FC<CommonWorkspaceAlertModalProps> = ({
  isVisible,
  onCancel,
  onConfirm,
}) => {
  const {
    state: { workspaceList },
  } = useContext(AppContext) as AppContextType;
  return (
    <Modal
      open={isVisible}
      onCancel={onCancel}
      className="common-key-modal"
      footer={null}
      width={MODAL_SIZE.medium}
    >
      <Flex vertical align="center" gap={16}>
        <Warning size={48} weight="fill" color="var(--caution-on-surface)" />
        <Flex vertical>
          <Title
            level={4}
            className="text-center text-neutrals-9 font-secondary"
          >
            Upgrade required for new workspace
          </Title>
          <Flex vertical align="center" gap={16}>
            <Paragraph className="text-sm text-content-secondary mb-0 text-center">
              To create a new workspace, you need to upgrade all of your
              existing workspaces to premium. Please upgrade to proceed.
            </Paragraph>
            <CommonButton
              key="confirm"
              type="primary"
              onClick={() => {
                onConfirm(workspaceList?.[0]?.uuid || '');
              }}
            >
              Upgrade to premium
            </CommonButton>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default CommonWorkspaceAlertModal;
