import { ApolloError, useMutation } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../../AppContext';
import { FormThemeMode } from '../../../__generated__/graphql';
import { ROUTES, TEMPLATE_ID } from '../../../common/constants';
import LoaderComponent from '../../../components/common/LoaderComponent';
import useRouter from '../../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../../types/appContext.type';
import { USE_FORM_TEMPLATE } from '../graphql/mutations';

export default function CreateFormBySlug() {
  const { params, navigate } = useRouter();
  const { slug } = params;
  const { dispatch, getWorkspaceId, updateCurrentUser } = useContext(
    AppContext,
  ) as AppContextType;
  const [useFormTemplateMutate, { loading }] = useMutation(USE_FORM_TEMPLATE, {
    onError: () => {},
    onCompleted: (res) => {
      // clean up active theme ids
      dispatch({
        type: AppActionType.setActiveThemeIds,
        data: {
          activeThemeId: '',
          activeThemeVariationId: '',
          activeMode: FormThemeMode.Auto,
        },
      });
      // clean up template id
      localStorage.removeItem(TEMPLATE_ID);
      navigate(`${ROUTES.EDITOR}/${res.useFormTemplate?.data?.id}`);
    },
  });

  const handleErrorModal = (error: ApolloError) => {
    const code = error?.graphQLErrors?.[0]?.extensions?.code;
    const uuid = getWorkspaceId();
    if (uuid) {
      updateCurrentUser(uuid, () => {
        if (code === 'REACHED_AT_FORM_CREATION_LIMIT') {
          navigate(`${ROUTES.PLAN_BILLING}/${uuid}`);
        } else {
          navigate(`${ROUTES.MAIN}/${uuid}`);
        }
      });
    }
  };

  useEffect(() => {
    if (slug) {
      useFormTemplateMutate({
        variables: {
          data: {
            formTemplateSlug: slug,
          },
        },
        onError: (err) => {
          handleErrorModal(err);
        },
      });
    } else {
      navigate(ROUTES.MAIN);
    }
  }, [slug]);

  if (loading) return <LoaderComponent />;
  return null;
}
