/* eslint-disable no-unused-vars */
import { Dispatch } from 'react';
import {
  Form,
  FormDesign,
  FormSubmissionType,
  FormTheme,
  FormThemeMode,
  User,
  Workspace,
} from '../__generated__/graphql';
import { FormSchemaType } from '../modules/form/form.types';
import { ID, Permissions } from './common.type';

type AppStateType = {
  formCreateLoading?: boolean;
  formFetchLoading?: boolean;
  formCreateError?: boolean;
};

type FormItemsType = {
  items?: FormSchemaType[];
  activeId?: ID;
};
type ToursType = {
  editor?: boolean;
  preview?: boolean;
};

export type AppState = {
  currentUser: User;
  authenticated: boolean;
  authToken: string | null;
  currentRole?: string;
  refreshToken?: string | null;
  redirectRoute?: string;
  formSettings?: Form & { previewType?: FormSubmissionType };
  appState?: AppStateType;
  systemThemeMode?: FormThemeMode;
  themeList: FormTheme[];
  activeThemeId: FormDesign['themeId'];
  activeThemeVariationId: FormDesign['themeVariationId'];
  activeMode: FormDesign['themeMode'];
  removeBranding: FormDesign['removeBranding'];
  formItems: FormItemsType;
  refetchForm?: {
    refetch: boolean;
    prompt?: string;
    oldPrompt?: string;
  };
  triggerTour: ToursType;
  workspaceList: Workspace[];
};

export type ThemeIds = {
  activeThemeId?: FormDesign['themeId'];
  activeThemeVariationId?: FormDesign['themeVariationId'];
  activeMode?: FormDesign['themeMode'];
  removeBranding?: FormDesign['removeBranding'];
};

export enum AppActionType {
  setCurrentUser = 'SET_CURRENT_USER',
  setAuthenticated = 'SET_AUTHENTICATED',
  setAuthToken = 'SET_TOKEN',
  setCurrentRole = 'SET_CURRENT_ROLE',
  setRefreshToken = 'SET_REFRESH_TOKEN',
  setRedirectRoute = 'SET_REDIRECT_ROUTE',
  setSystemThemeMode = 'SET_SYSTEM_THEME_MODE',
  setAppThemes = 'SET_APP_THEMES',
  setActiveThemeIds = 'SET_ACTIVES_THEME_ID',
  setAppState = 'SET_APP_STATE',
  logout = 'LOGOUT',
  setFormSettings = 'SET_FORM_DATA',
  setFormItems = 'SET_FORM_ITEMS',
  refetchForm = 'REFETCH_FORM',
  triggerTour = 'TRIGGER_TOUR',
  userWorkspaces = 'USER_WORKSPACES',
}

export type AppAction =
  | { type: AppActionType.setCurrentUser; data: User }
  | { type: AppActionType.setAuthenticated; data: boolean }
  | { type: AppActionType.setAuthToken; data: string | null }
  | { type: AppActionType.setRefreshToken; data: string | null }
  | { type: AppActionType.setCurrentRole; data: string }
  | { type: AppActionType.setRedirectRoute; data?: string }
  | { type: AppActionType.userWorkspaces; data: Workspace[] }
  | { type: AppActionType.setAppThemes; data?: FormTheme[] }
  | { type: AppActionType.setActiveThemeIds; data?: ThemeIds }
  | { type: AppActionType.setAppState; data?: AppStateType }
  | { type: AppActionType.setSystemThemeMode; data?: FormThemeMode }
  | { type: AppActionType.triggerTour; data?: ToursType }
  | { type: AppActionType.logout }
  | {
      type: AppActionType.setFormSettings;
      data: Form & { previewType?: FormSubmissionType };
    }
  | { type: AppActionType.setFormItems; data?: FormItemsType }
  | {
      type: AppActionType.refetchForm;
      data: {
        refetch: boolean;
        prompt?: string;
        oldPrompt?: string;
      };
    };

export type AppContextType = {
  state: AppState;
  dispatch: Dispatch<AppAction>;
  getToken: () => string | null;
  getWorkspaceId: () => string | null;
  isAuthenticated: () => boolean;
  getRefreshToken: () => string | null;
  hasPermission: (requiredPermission: Permissions) => boolean;
  initializeAuth: (
    authToken: string | null,
    userData: User | null,
    refreshToken?: string | null,
    route?: string,
  ) => void;
  getCurrentUser: () => void;
  updateCurrentUser: (uuid: string, callback: () => void) => void;
};
