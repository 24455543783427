import { useMutation } from '@apollo/client';
import { Alert, Flex, Form, Input, Modal } from 'antd';
import Link from 'antd/es/typography/Link';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import React, { useContext, useEffect, useState } from 'react';
import { FormStatus } from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import { formValidation } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { UPDATE_FORM_TITLE } from '../../modules/form/graphql/mutations';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import CommonButton from '../primitives/CommonButton';

type CommonFormEditModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: (isRefetch?: boolean) => void;
};

const CommonFormEditModal: React.FC<CommonFormEditModalProps> = ({
  isVisible,
  onCancel,
  onConfirm,
}) => {
  const [form] = Form.useForm();
  const { params } = useRouter();
  const formId = params?.id as string;
  const [isDisabled, setIsDisabled] = useState(true);
  const {
    state: { formSettings },
    dispatch,
  } = useContext(AppContext) as AppContextType;

  const [updateFormTitle, { loading }] = useMutation(UPDATE_FORM_TITLE, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  useEffect(() => {
    form.setFieldsValue({
      title: formSettings?.title,
      prompt: formSettings?.structures?.[0]?.overview,
    });
  }, [formSettings, form]);

  const handleConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        updateFormTitle({
          variables: {
            data: {
              title: values.title,
            },
            where: {
              formId,
            },
          },
          onCompleted: (res) => {
            const isRefetch =
              values.prompt.trim() !==
              formSettings?.structures?.[0]?.overview?.trim();
            if (isRefetch) {
              dispatch({
                type: AppActionType.refetchForm,
                data: {
                  refetch: true,
                  prompt: values?.prompt,
                  oldPrompt: formSettings?.structures?.[0]?.overview || '',
                },
              });
            }
            dispatch({
              type: AppActionType.setFormSettings,
              data: {
                title: res.updateForm?.data?.title,
                structures: [
                  {
                    structure: formSettings?.structures?.[0]?.structure,
                    overview: values?.prompt,
                  },
                ],
              },
            });
            onConfirm(isRefetch);
            form.resetFields();
          },
        });
      })
      .catch(() => {
        // do nothing
      });
  };

  const handleCancel = () => {
    onCancel();
  };

  const ModalTitle = () => (
    <Title level={4} className="font-secondary text-content-primary">
      Edit your form details
    </Title>
  );

  return (
    <Modal
      title={<ModalTitle />}
      open={isVisible}
      width={700}
      onCancel={handleCancel}
      className="common-key-modal"
      footer={[
        <CommonButton key="cancel" onClick={handleCancel} disabled={loading}>
          Cancel
        </CommonButton>,
        <CommonButton
          key="confirm"
          type="primary"
          onClick={handleConfirm}
          loading={loading}
        >
          Save
        </CommonButton>,
      ]}
    >
      <Flex vertical align="flex-start" gap={16}>
        <Paragraph className="text-sm text-content-secondary mb-0">
          Feel free to update your form name and prompt.
        </Paragraph>
        <Form form={form} layout="vertical" className="w-full">
          <Form.Item
            name="title"
            rules={[formValidation.required]}
            label="Form Name"
            required={false}
          >
            <Input placeholder="Type here..." className="w-full" />
          </Form.Item>
          <div className="relative">
            <Form.Item
              name="prompt"
              label="Refined Prompt"
              required={false}
              className="mb-8"
            >
              <Input.TextArea
                placeholder="Type here..."
                className="w-full"
                autoSize={{ minRows: 3, maxRows: 7 }}
                disabled={isDisabled}
              />
            </Form.Item>
            {formSettings?.status === FormStatus.Draft && (
              <Link
                disabled={!isDisabled}
                underline
                onClick={(e) => {
                  e.preventDefault();
                  setIsDisabled(false);
                }}
                className="absolute right-0 top-0"
              >
                Edit
              </Link>
            )}
          </div>
        </Form>
        {!isDisabled && (
          <Flex vertical align="flex-start" gap={4} className="w-full">
            <Alert
              type="warning"
              message="Note: If you change the prompt then the all the questions will be regenerated and the changes done by you will be lost."
              className="w-full text-sm"
            />
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};

export default CommonFormEditModal;
