import { Plus } from '@phosphor-icons/react';
import { Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
import CommonButton from '../../../../components/primitives/CommonButton';
import { AppContextType } from '../../../../types/appContext.type';
import { Permissions } from '../../../../types/common.type';

export default function EmptyFormList({
  onAddQuestion,
}: {
  onAddQuestion: () => void;
}) {
  const { hasPermission } = useContext(AppContext) as AppContextType;
  const editAccess = hasPermission(Permissions.WRITE);

  return (
    <Flex vertical gap={24} align="center">
      <Title
        level={3}
        className="text-content-primary mb-0 font-secondary semi-bold"
      >
        Start by adding your first question
      </Title>
      <CommonButton
        type="primary"
        onClick={onAddQuestion}
        icon={<Plus size={16} color="var(--content-inverse-primary)" />}
        disabled={!editAccess}
      >
        Add Question
      </CommonButton>
    </Flex>
  );
}
