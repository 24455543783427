import { Question } from '@phosphor-icons/react';
import { Tooltip } from 'antd';
import Gleap from 'gleap';
import CommonButton from '../../primitives/CommonButton';

export default function GleapBotPopup() {
  const handleToggleGleap = () => {
    const isOpened = Gleap.isOpened();
    if (isOpened) {
      Gleap.close();
    } else {
      Gleap.open();
    }
  };
  return (
    <Tooltip title="Help Center">
      <CommonButton
        shape="circle"
        shadow={false}
        icon={
          <Question
            color="var(--content-primary)"
            size={18}
            weight="regular"
            className="cursor-pointer"
            onClick={handleToggleGleap}
          />
        }
        onClick={handleToggleGleap}
      />
    </Tooltip>
  );
}
