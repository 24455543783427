import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import ZinqIcon from '../../assets/logo/zinq-icon.png';
import { ROUTES } from '../../common/constants';
import { AppContextType } from '../../types/appContext.type';
import CommonButton from '../primitives/CommonButton';

const Error404 = () => {
  const { getWorkspaceId } = useContext(AppContext) as AppContextType;
  return (
    <Flex className="max-h-screen" vertical align="center" gap={32}>
      <img src={ZinqIcon} alt="zinq icon" height={112} width={112} />
      <Flex vertical gap={8}>
        <Title level={2} className="mb-0 font-secondary text-center">
          404. Page not found
        </Title>
        <Paragraph className="mb-0 text-content-secondary text-center">
          Looks like you got lost. Let’s get you back on track!
        </Paragraph>
      </Flex>
      <Link to={`${ROUTES.MAIN}${getWorkspaceId()}`}>
        <CommonButton type="primary">Return to Home</CommonButton>
      </Link>
    </Flex>
  );
};

export default Error404;
