import { gql } from '../../../__generated__';

export const DELETE_USER_ACCOUNT = gql(`
 mutation DeleteAccount {
  deleteAccount {
    message
  }
}`);

export const ACTIVATE_SUBSCRIPTION_PLAN = gql(`
  mutation ActivateSubscriptionPlan($data: SubscriptionPlanUniqueInput!) {
  activateSubscriptionPlan(data: $data) {
    message
    data {
      features
    }
  }
}`);
