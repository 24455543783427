import { Download } from '@phosphor-icons/react';
import { Flex, Modal } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import QRCode from 'react-qr-code';
import { handleDownloadQRCode } from '../../common/utils';
import CommonButton from '../primitives/CommonButton';

export default function CommonQRCodeModal({
  qrModalVisible,
  handleCloseQRModal,
  link,
}: {
  qrModalVisible: boolean;
  handleCloseQRModal: () => void;
  link: string;
}) {
  return (
    <Modal
      title={<Title level={4}>QR Code</Title>}
      open={qrModalVisible}
      onCancel={handleCloseQRModal}
      footer={null}
      width={400}
    >
      <Flex vertical align="center" gap={16}>
        <QRCode id="qr-code-svg" value={link} size={200} />
        <Paragraph className="text-sm text-neutrals-700">
          Scan this QR code to access the form link
        </Paragraph>
        <Flex gap={16}>
          <CommonButton
            type="default"
            icon={<Download size={16} />}
            onClick={handleDownloadQRCode}
          >
            Download
          </CommonButton>
        </Flex>
      </Flex>
    </Modal>
  );
}
