import { useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { User } from '../../__generated__/graphql';
import { ROUTES, TOKEN } from '../../common/constants';
import LoaderComponent from '../../components/common/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import { REFRESH_TOKEN } from './graphql/mutations';
import { GET_CURRENT_USER } from './graphql/queries';

const RefreshToken = () => {
  const {
    initializeAuth,
    getRefreshToken,
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;
  const { navigate } = useRouter();
  const refreshToken = getRefreshToken();

  const successCallback = (
    accessToken: string | null,
    userData: User | null,
  ) => {
    initializeAuth(accessToken, userData);
    navigate(ROUTES?.MAIN);
  };
  const [getCurrentUser, { loading: fetchLoading }] = useLazyQuery(
    GET_CURRENT_USER,
    {
      fetchPolicy: 'network-only',
      onError: () => {},
    },
  );

  const [refresh, { loading }] = useMutation(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const accessToken = response?.refreshToken?.accessToken ?? null;
      localStorage.setItem(TOKEN, accessToken!);
      getCurrentUser({
        onCompleted: (res) => {
          successCallback(accessToken, res?.currentUser ?? null);
        },
      });
    },
    onError: () => {
      navigate(ROUTES?.LOGOUT, { replace: true });
    },
  });

  useEffect(() => {
    if (refreshToken)
      refresh({
        variables: {
          data: {
            refreshToken,
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || fetchLoading) return <LoaderComponent />;

  return null;
};

export default RefreshToken;
