import { Link } from 'react-router-dom';
import { FormThemeMode } from '../../__generated__/graphql';
import LogoDarkImg from '../../assets/logo/logo-dark.svg';
import LogoLightImg from '../../assets/logo/logo-light.svg';
export default function Logo({
  width = 140,
  mode,
  link,
}: {
  width?: number;
  mode: FormThemeMode;
  link?: string;
}) {
  if (link) {
    return (
      <Link to={link}>
        <img
          src={mode === FormThemeMode.Light ? LogoDarkImg : LogoLightImg}
          alt="zinq logo"
          width={width}
        />
      </Link>
    );
  }
  return (
    <img
      src={mode === FormThemeMode.Light ? LogoDarkImg : LogoLightImg}
      alt="zinq logo"
      width={width}
    />
  );
}
