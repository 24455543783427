import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Col,
  ConfigProvider,
  Divider,
  Flex,
  Form,
  InputNumber,
  Row,
  Select,
  UploadFile,
} from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { map } from 'lodash';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import {
  FormTemplate,
  ListFormCategoriesSortOnField,
  SortOrder,
  UpdateFormTemplateDataInput,
} from '../../../../__generated__/graphql';
import { DEFAULT_AUTO_SAVE_TIME } from '../../../../common/constants';
import CommonSwitch from '../../../../components/primitives/CommonSwitch';
import useRouter from '../../../../hooks/useRouter';
import { UPDATE_FORM_TEMPLATE } from '../../graphql/mutations';
import {
  GET_FORM_CATEGORIES_TEMPLATE,
  GET_TEMPLATE_DETAIL,
} from '../../graphql/queries';
import FormLoading from '../utils/FormLoading';
import { CommonPageTypes } from './pages.types';

export default function TemplateSetting({ onBack }: CommonPageTypes) {
  const [details, setDetails] = useState<FormTemplate>();
  const [form] = Form.useForm();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [link, setLink] = useState<string>('');
  const { params } = useRouter();
  const formId = params?.id as string;

  const [getTemplateDetails, { loading }] = useLazyQuery(GET_TEMPLATE_DETAIL, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const [updateTemplateDetails] = useMutation(UPDATE_FORM_TEMPLATE, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const { data: categoryList, loading: fetchLoading } = useQuery(
    GET_FORM_CATEGORIES_TEMPLATE,
    {
      variables: {
        filter: {
          templateLimit: 0,
        },
        sort: [
          {
            sortBy: SortOrder.Asc,
            sortOn: ListFormCategoriesSortOnField.Name,
          },
        ],
      },
    },
  );

  const handleAutoSave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      const allFields: UpdateFormTemplateDataInput = form.getFieldsValue();
      updateTemplateDetails({
        variables: {
          where: {
            formId,
          },
          data: {
            ...allFields,
          },
        },
        onCompleted: () => {
          // setLink(getFormLink(details?.key || '', allFields?.embedConfigs));
        },
      });
    }, DEFAULT_AUTO_SAVE_TIME);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '.') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    form.setFieldsValue(details);
  }, [details, form]);

  useEffect(() => {
    if (formId) {
      getTemplateDetails({
        variables: {
          where: {
            formId,
          },
        },
        onCompleted: (res) => {
          const formData = res.formTemplateAdmin;
          setDetails(formData as FormTemplate);
        },
      });
    }
  }, [formId]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#a9aeb1',
        },
        components: {
          Form: {
            labelColor: 'var(--content-secondary)',
            labelFontSize: 14,
            verticalLabelPadding: 0,
            itemMarginBottom: 0,
            fontWeightStrong: 500,
          },
          Input: {
            borderRadius: 16,
            colorBorder: 'var(--border-primary)',
            colorText: 'var(--content-primary)',
            colorBgContainer: 'var(--surface-secondary)',
          },
          InputNumber: {
            borderRadius: 16,
            colorText: 'var(--content-primary)',
            colorBorder: 'var(--border-primary)',
            colorBgContainer: 'var(--surface-secondary)',
          },
          Select: {
            borderRadius: 16,
            colorText: 'var(--content-primary)',
            colorBorder: 'var(--border-primary)',
            optionActiveBg: 'var(--surface-tertiary)',
            optionSelectedBg: 'var(--surface-tertiary)',
            colorBgContainer: '#ff0000',
          },
          Tag: {
            borderRadius: 16,
          },
          DatePicker: {
            borderRadius: 16,
            colorBorder: 'var(--border-primary)',
          },
        },
      }}
    >
      {!loading ? (
        <Form
          autoComplete="off"
          initialValues={details}
          form={form}
          onFieldsChange={handleAutoSave}
          className="edit-template-form"
        >
          <div className="scroll-wrapper-body">
            <Row gutter={[12, 24]}>
              <Col span={24}>
                <Flex gap={8} justify="space-between" align="center">
                  <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                    Show on webapp
                  </Paragraph>
                  <Form.Item name={['displayConfig', 'showOnWebApp']}>
                    <CommonSwitch type="primary" />
                  </Form.Item>
                </Flex>
              </Col>
              <Col span={24}>
                <Flex gap={8} justify="space-between" align="center">
                  <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                    Show on website
                  </Paragraph>
                  <Form.Item name={['displayConfig', 'showOnWeb']}>
                    <CommonSwitch type="primary" />
                  </Form.Item>
                </Flex>
              </Col>
              <Col span={24}>
                <Flex gap={8} justify="space-between" align="center">
                  <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                    Show on website homepage
                  </Paragraph>
                  <Form.Item name={['displayConfig', 'showOnWebHomePage']}>
                    <CommonSwitch type="primary" />
                  </Form.Item>
                </Flex>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Choose Category"
                  name="categoryId"
                  labelCol={{ span: 24 }}
                >
                  <Select
                    placeholder="Select category"
                    showSearch
                    loading={fetchLoading}
                    disabled={fetchLoading}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={map(categoryList?.formCategories?.data, (item) => {
                      return {
                        label: item?.name,
                        value: item?.id,
                      };
                    })}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Flex gap={8} justify="space-between" align="center">
                  <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                    Weightage of template
                  </Paragraph>
                  <Form.Item name="weightage">
                    <InputNumber
                      min={0}
                      precision={0}
                      controls={false}
                      onKeyPress={handleKeyPress}
                      placeholder="Type here..."
                    />
                  </Form.Item>
                </Flex>
              </Col>
              <Col span={24}>
                <Divider className="mb-0 mt-0" dashed />
              </Col>
              <Col span={24}>
                <Form.Item
                  name="tags"
                  label="Tags"
                  labelCol={{ span: 24 }}
                  className="tags-list-item"
                >
                  <Select mode="tags" placeholder="Type here... " allowClear />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  labelCol={{ span: 24 }}
                  valuePropName="value"
                  getValueFromEvent={(htmlContent) => htmlContent}
                >
                  <ReactQuill placeholder="Type here..." />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      ) : (
        <FormLoading />
      )}
    </ConfigProvider>
  );
}
