import type { TourProps } from 'antd';
import { Tour } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { AppActionType, AppContextType } from '../../../types/appContext.type';
import CustomIndicator from './CustomIndicator';

type EditorTourProps = {
  refs: React.RefObject<HTMLElement>[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

const EditorTour: React.FC<EditorTourProps> = ({
  refs,
  open,
  onOpenChange,
}) => {
  const { dispatch } = useContext(AppContext) as AppContextType;
  const handleClose = () => {
    dispatch({
      type: AppActionType.triggerTour,
      data: { editor: false, preview: true },
    });
  };
  const steps: TourProps['steps'] = [
    {
      title: '',
      description: 'Add, edit and manage form questions.',
      target: () => refs[0].current as HTMLElement,
      onClose: handleClose,
    },
    {
      title: '',
      description: 'Design your form to standout!',
      target: () => refs[1].current as HTMLElement,
      onClose: handleClose,
    },
    {
      title: '',
      description: 'Explore settings to personalize your form for you.',
      target: () => refs[2].current as HTMLElement,
      onClose: handleClose,
      onFinish: handleClose,
    },
  ];

  return (
    <Tour
      open={open}
      onClose={() => onOpenChange(false)}
      steps={steps}
      gap={{
        radius: 8,
      }}
      indicatorsRender={(current, total) => (
        <CustomIndicator current={current} total={total} />
      )}
    />
  );
};

export default EditorTour;
