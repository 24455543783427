import { useMutation } from '@apollo/client';
import { Flex, Form, Input, Spin, Statistic } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Link from 'antd/es/typography/Link';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { FormThemeMode, SignUpInputData } from '../../__generated__/graphql';
import { COMPANY_INFO, ROUTES } from '../../common/constants';
import { formValidation, getCookie, setCookie } from '../../common/utils';
import Logo from '../../components/common/Logo';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { RESEND_VERIFICATION_EMAIL, USER_SIGNUP } from './graphql/mutations';

const RegisterEmail = () => {
  const { navigate } = useRouter();
  const [form] = useForm();
  const timerCookie = getCookie('timer') || null;
  const emailCookie = getCookie('email') || null;
  const [timerValue, setTimerValue] = useState(timerCookie);
  const [step, setStep] = useState(emailCookie ? 2 : 1);

  const [userSignUpMutate, { loading }] = useMutation(USER_SIGNUP, {
    onError: () => {},
  });
  const [resendEmailMutate, { loading: resendLoading }] = useMutation(
    RESEND_VERIFICATION_EMAIL,
    {
      onError: () => {},
    },
  );

  const renderTitle = useMemo(() => {
    switch (step) {
      case 1:
        return 'Sign Up';
      case 2:
        return 'Email sent';
      default:
        return '';
    }
  }, [step]);

  const manageTimer = (email: string) => {
    const timerStamp = dayjs()
      ?.add(COMPANY_INFO?.resendEmailTimer, 'minutes')
      ?.format();
    setCookie('timer', timerStamp, COMPANY_INFO?.resendEmailTimer);
    setCookie('email', email, null);
    setTimerValue(timerStamp);
    setStep(2);
  };

  const handleResendEmail = () => {
    const email = form.getFieldValue('email') || getCookie('email');
    resendEmailMutate({
      variables: {
        data: { email },
      },
      onCompleted: () => {
        manageTimer(email);
      },
    });
  };

  const handleRegister = (payload: SignUpInputData) => {
    const { name, email, password } = payload;
    userSignUpMutate({
      variables: {
        data: { name, email, password },
      },
      onCompleted: () => {
        manageTimer(email);
      },
      onError: (err) => {
        form.setFields([
          {
            name: 'email',
            errors: [err?.message],
          },
        ]);
      },
    });
  };

  const handleTimerFinish = () => {
    setTimerValue(null);
  };

  const renderDescription = useMemo(() => {
    const sentEmail = form.getFieldValue('email') || getCookie('email');
    switch (step) {
      case 1:
        return '';
      case 2:
        return `We have sent an email to ${sentEmail} for verification.`;
      default:
        return '';
    }
  }, [step]);

  const handlePageRedirect = (link: string) => {
    const baseUrl = process.env.REACT_APP_WEBSITE_BASE_URL;
    window.open(`${baseUrl}${link}`, '_blank');
  };

  return (
    <section className="auth-wrapper">
      <div className="auth-form">
        <div className="flex justify-center">
          <Logo mode={FormThemeMode.Light} link={ROUTES.LOGIN} />
        </div>
        <Flex gap={4} vertical>
          <Title
            level={2}
            className="text-center semi-bold font-secondary text-content-primary"
          >
            {renderTitle}
          </Title>
          <Paragraph className="mb-0 text-center text-content-tertiary">
            {renderDescription}
          </Paragraph>
        </Flex>
        {step === 1 && (
          <>
            <Spin spinning={loading}>
              <div className="social-groups">
                <Form
                  form={form}
                  name="register"
                  onFinish={handleRegister}
                  layout="vertical"
                  scrollToFirstError
                  autoComplete="off"
                >
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      formValidation.required,
                      formValidation.name,
                      formValidation.whitespace,
                    ]}
                  >
                    <Input placeholder="Enter your name" />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[formValidation.required, formValidation.email]}
                  >
                    <Input placeholder="Enter your email" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    hasFeedback
                    rules={[formValidation.required, formValidation.password]}
                  >
                    <Input.Password
                      placeholder="Enter password"
                      maxLength={255}
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      formValidation.required,
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('Passwords do not match!'),
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Re-enter password"
                      maxLength={255}
                    />
                  </Form.Item>

                  <Form.Item>
                    <CommonButton type="primary" htmlType="submit" block>
                      Continue
                    </CommonButton>
                  </Form.Item>
                </Form>
              </div>
            </Spin>
            <div>
              <Paragraph className="mb-0 text-center text-content-tertiary">
                Already have an account?{' '}
                <Link
                  onClick={() => {
                    navigate(ROUTES.LOGIN);
                  }}
                  underline
                >
                  Sign In
                </Link>
              </Paragraph>
            </div>
            <div>
              <Paragraph className="mb-0 text-center text-content-tertiary">
                By Signing Up, you agree to our
              </Paragraph>
              <Paragraph className="mb-0 text-center text-content-tertiary">
                <Link
                  onClick={() => handlePageRedirect(ROUTES.T_AND_C)}
                  underline
                >
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link
                  onClick={() => handlePageRedirect(ROUTES.PRIVACY_POLICY)}
                  underline
                >
                  Privacy Policy.
                </Link>
              </Paragraph>
            </div>
          </>
        )}
        {step === 2 &&
          (timerValue ? (
            <CommonButton disabled>
              Resend in
              <Statistic.Countdown
                format="mm:ss"
                value={timerValue}
                onFinish={handleTimerFinish}
              />
            </CommonButton>
          ) : (
            <CommonButton onClick={handleResendEmail} loading={resendLoading}>
              Resend
            </CommonButton>
          ))}
      </div>
    </section>
  );
};

export default RegisterEmail;
