import { useLazyQuery, useMutation } from '@apollo/client';
import { Trash } from '@phosphor-icons/react';
import { Col, Flex, Form, Input, Row, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Paragraph from 'antd/es/typography/Paragraph';
import { omit } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  UserProfileData,
  WorkspaceMemberRoles,
} from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import {
  DEFAULT_AUTO_SAVE_TIME,
  DEFAULT_WORKSPACE_LABEL,
  FORM_CHARACTER_LIMIT,
  ROUTES,
} from '../../common/constants';
import { handleGraphQlError, handleGraphQlSuccess } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import DeleteAccountModal from '../profile/modals/DeleteAccountModal';
import {
  DELETE_USER_WORKSPACE,
  UPDATE_WORKSPACE_SETTINGS,
} from './graphql/mutations';
import { GET_WORKSPACE_DETAILS } from './graphql/queries';

export default function WorkspaceSettings() {
  const [open, setOpen] = useState(false);
  const { navigate } = useRouter();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [form] = useForm();
  const { params } = useRouter();
  const [isDefault, setIsDefault] = useState<boolean>(true);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const {
    state: { workspaceList, currentUser },
    dispatch,
    updateCurrentUser,
  } = useContext(AppContext) as AppContextType;

  const [updateWorkspaceMutate, { loading: updateLoading }] = useMutation(
    UPDATE_WORKSPACE_SETTINGS,
    {
      onError: (err) => {
        handleGraphQlError(err.message);
      },
    },
  );

  const [fetchWorkspace, { loading }] = useLazyQuery(GET_WORKSPACE_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      form.setFieldsValue(omit(res.workspace?.data, ['default']));
      setIsDefault(res.workspace?.data?.default || false);
      setIsOwner(
        res?.workspace?.data?.workspaceMembers?.[0]?.role ===
          WorkspaceMemberRoles.Owner,
      );
    },
    onError: () => {
      navigate(ROUTES.NOT_FOUND);
    },
  });

  const [deleteWorkspace, { loading: deleteLoading }] = useMutation(
    DELETE_USER_WORKSPACE,
    {
      onError: () => {},
    },
  );

  const handleDetailsSave = (fields: UserProfileData) => {
    fields.name = fields?.name?.trim() || DEFAULT_WORKSPACE_LABEL;
    updateWorkspaceMutate({
      variables: {
        data: { ...fields, name: fields?.name },
        where: {
          workspaceUuid: params?.id as string,
        },
      },
      onCompleted: (res) => {
        const index = workspaceList.findIndex(
          (item) => item.uuid === params?.id,
        );
        if (index !== -1) {
          const updatedValue = [...workspaceList]; // clone to perform write access
          updatedValue.splice(index, 1, {
            ...workspaceList[index],
            ...fields,
          });
          dispatch({
            type: AppActionType.userWorkspaces,
            data: updatedValue,
          });
          handleGraphQlSuccess(res.updateWorkspace?.message);
        }
      },
    });
  };

  useEffect(() => {
    if (params?.id) {
      fetchWorkspace({
        variables: {
          where: {
            workspaceUuid: params?.id as string,
          },
        },
      });
    }
  }, [params?.id]);

  const handleOnOk = () => {
    deleteWorkspace({
      variables: {
        where: {
          workspaceUuid: params?.id as string,
        },
      },
      onCompleted: (data) => {
        handleGraphQlSuccess(data.deleteWorkspace?.message);
        const uuid = currentUser?.defaultWorkspace?.uuid;
        if (uuid) {
          updateCurrentUser(uuid, () => {
            navigate(`${ROUTES.MAIN}${uuid}`);
          });
        }
      },
    });
  };

  const handleOnCancel = () => {
    setOpen(false);
  };

  const handleAutoSave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      const allFields = form.getFieldsValue();
      handleDetailsSave(allFields);
    }, DEFAULT_AUTO_SAVE_TIME);
  };

  return (
    <div className="profile-form">
      <Spin spinning={loading || updateLoading}>
        <Form
          form={form}
          name="contact-form"
          onFieldsChange={handleAutoSave}
          layout="vertical"
          autoComplete="off"
          disabled={!isOwner}
        >
          <Form.Item name="name" label="Workspace name">
            <Input
              placeholder="Type here..."
              maxLength={FORM_CHARACTER_LIMIT.workspaceNameLimit}
            />
          </Form.Item>
          {!isDefault && isOwner && (
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <Flex
                  align="center"
                  gap={8}
                  className="w-max-content cursor-pointer"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Trash color="var(--danger-on-surface)" />
                  <Paragraph className="mb-0 text-danger-on-surface">
                    Delete Workspace
                  </Paragraph>
                </Flex>
              </Col>
            </Row>
          )}
        </Form>
      </Spin>
      {open && (
        <DeleteAccountModal
          title="Delete Workspace"
          onOk={handleOnOk}
          open={open}
          onCancel={handleOnCancel}
          okLoading={deleteLoading}
        />
      )}
    </div>
  );
}
